//Base imports
import './index.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import RegisterState from './context/register/registerState'

import { connect } from 'react-redux'

//Context
import setAuthToken from 'utils/setAuthToken'

//Pages
import Content from './pages/Content'
import ContentCategory from 'pages/Content/ContentCategory'
import ContentGoal from 'pages/Content/ContentGoal'
import Panel from './pages/Panel'
import Article from 'pages/Content/Article'
import ProfileUser from './pages/profile/sections/ProfileUser'
import ProfileMedical from './pages/profile/sections/ProfileMedical'
import ProfileObjective from './pages/profile/sections/ProfileObjective'
import ProfileEquips from './pages/profile/sections/ProfileEquips'
import ProfilePassword from './pages/profile/sections/ProfilePassword'
import ProfileTerms from 'pages/profile/sections/ProfileTerms'
import ProfilePolicies from 'pages/profile/sections/ProfilePolicies'
import Faq from './pages/profile/sections/Faq'
import ProfileMenu from './pages/profile/ProfileMenu'
import Routine from './pages/Routine'
import Launch from './pages/Launch'
import RegisterRouter from './pages/register/RegisterRouter'
import PrivateRoute from 'components/homescreen/PrivateRoute'
import ForgotPassword from 'pages/register/ForgotPassword'
import { Fragment } from 'react'
import Loading from 'components/Loading'
import Trainer from 'pages/Trainer'
import Loader from 'Loader'
import { GifComponent } from 'context/gif'

if (localStorage.access_token) setAuthToken(localStorage.access_token)

const App = ({ state }: any) => {
  const { loading } = state

  return (
    <Fragment>
      <RegisterState>
        <Router>
          <Trainer />
          <GifComponent />
          {loading && <Loading />}
          <Switch>
            <Route exact path="/" component={Loader} />
            <Route exact path="/login" component={Launch} />
            <Route exact path="/register" component={RegisterRouter} />
            <Route exact path="/forgot-password" component={ForgotPassword} />

            <PrivateRoute home exact path="/home/panel" component={Panel} />

            <PrivateRoute home exact path="/home/routine" component={Routine} />

            <PrivateRoute home exact path="/home/content" component={Content} />
            <PrivateRoute home exact path="/home/content/article/:id" component={Article} />
            <PrivateRoute home exact path="/home/content/category/:id" component={ContentCategory} />
            <PrivateRoute home exact path="/home/content/goal/:id" component={ContentGoal} />

            <PrivateRoute home exact path="/home/profile/" component={ProfileMenu} />
            <PrivateRoute exact path="/home/profile/user" component={ProfileUser} />
            <PrivateRoute exact path="/home/profile/med" component={ProfileMedical} />
            <PrivateRoute exact path="/home/profile/objective" component={ProfileObjective} />
            <PrivateRoute exact path="/home/profile/equips" component={ProfileEquips} />
            <PrivateRoute exact path="/home/profile/password" component={ProfilePassword} />
            <PrivateRoute exact path="/home/profile/faq" component={Faq} />
            <PrivateRoute exact path="/home/profile/terms" component={ProfileTerms} />
            <PrivateRoute exact path="/home/profile/policies" component={ProfilePolicies} />

            <Route exact path="*">
              404
            </Route>
          </Switch>
        </Router>
      </RegisterState>
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(App)
