//Hooks
import { useState, ChangeEvent, FormEvent, ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
//Componentes
import Button from 'components/layout/Button'
import { RadioButton } from 'components/layout/RadioButton'
import Spinner from 'components/Spinner'
import axios from 'axios'

interface FormObjectiveProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  children?: ReactElement
  initialState?: {}
  disabled?: boolean
  state: any
}

const FormObjective = ({ next, buttonText, getData, children, initialState, disabled, state }: FormObjectiveProps) => {
  const [loading, setLoading] = useState(true)
  const [goals, setGoals] = useState<any>()

  const [regData, setRegData] = useState({
    goal_id: -1,
  })

  useEffect(() => {
    setRegData({ ...regData, ...initialState })
    // eslint-disable-next-line
  }, [initialState])

  const { goal_id } = regData

  const check = (x: any) => {
    return x !== -1
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({ ...regData, [target.name]: Number(target.id) })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (check(goal_id)) {
      getData(regData)
      if (next) next()
    }
  }

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/goal', {
      params: { language_id: state.user?.language_id },
    })

    setGoals(res.data)
  }

  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner></Spinner>
      </div>
    )

  return (
    <form className="ath-container justify-between h-full" onSubmit={onSubmit}>
      <div className="grid-inputs w-product mb-10">
        <RadioButton
          options={goals.map((option: { id: number; name: string }) => [Number(option.id), option.name])}
          name={'goal_id'}
          onChange={onChange}
          value={goal_id}
        />
      </div>
      <div className="grid-inputs w-product">
        <div>{children}</div>
        <Button className="main-button mt-5" disabled={!disabled ? !check(goal_id) : disabled} text={buttonText} />
      </div>
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormObjective)
