import { MouseEventHandler, Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Navbar from 'components/homescreen/Navbar'
import Titlebar from 'components/homescreen/TitleBar'
import Store from 'pages/Store'
import Drawer from 'react-bottom-drawer'

import { connect } from 'react-redux'
import { closeStore } from 'state/action-creators'

interface PrivateRouteProps {
  path: string
  component: any
  exact: boolean
  home?: boolean
  onTrainerClick?: MouseEventHandler
  state?: any
  closeStore: () => void
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, home, ...rest } = props
  const { closeStore } = props
  const { isAuth, isStoreOpen } = props.state

  const MyComponent = () => {
    return (
      <Fragment>
        <div className="absolute w-full h-full">
          {home && <Titlebar />}
          <Component {...props} />
          {home && <Navbar onClick={props.onTrainerClick} />}
        </div>

        <Drawer
          isVisible={isStoreOpen}
          onClose={() => {
            closeStore()
          }}
        >
          <Store />
        </Drawer>
      </Fragment>
    )
  }

  return <Route {...rest} render={(props) => (!isAuth ? <Redirect to="/" /> : <MyComponent />)} />
}

PrivateRoute.defaultProps = {
  home: false,
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { closeStore })(PrivateRoute)
