import { ReactComponent as BrandIcon } from 'assets/brand.svg'

const TitleBar = () => {
  return (
    <div className="flex w-full h-8 justify-center items-center" style={{position: 'sticky'}}>
      <BrandIcon fill="#FF6900" className="h-2vh" />
    </div>
  )
}

export default TitleBar
