import './trackerDay.css'
import { connect } from 'react-redux'
interface TrackerDayProps {
  active?: boolean
  barSize: number
  weekday: '1' | '2' | '3' | '4' | '5' | '6' | '7'
  monthDay: string
  minutes: number
  big?: boolean
  state: any
}

const TrackerDay = (props: TrackerDayProps) => {
  const state = props.state

  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const { active, barSize, weekday, monthDay, minutes, big } = props

  const weekdayName = (index: string): string => ct[`panelWeekday${index}`] || null

  const heightPercent = `${barSize}%`

  let zero = false
  if (barSize === 0) {
    zero = true
  }

  return (
    <div
      className={`tracker-day-wrapper ${active ? 'day-active' : ''}`}
      style={{ height: `${big ? '175px' : '150px'}` }}
    >
      <div className="tracker-day-bar-weekday">{weekdayName(weekday)}</div>
      <div className="tracker-day-bar-monthday">{monthDay}</div>
      <div className="tracker-day-bar-wrapper">
        <div className={`tracker-day-bar ${zero ? 'zero' : ''}`} style={{ height: heightPercent }} />
      </div>
      <div className="tracker-day-bar-minutes">{zero ? '' : `${minutes}m`}</div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(TrackerDay)
