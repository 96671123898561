import './article-bar.css'
import { useHistory } from 'react-router-dom'
import { ReactComponent as GoBackIcon } from 'assets/go-back.svg'

interface ArticleBarProps {
  text: string
}
const ArticleBar = (props: ArticleBarProps) => {
  const history = useHistory()
  const { text } = props
  return (
    <div className="profile-bar-wrapper">
      <div className="profile-bar">
        <div className="absolute">
          <GoBackIcon
            fill="#666666"
            onClick={() => {
              history.goBack()
            }}
          />
        </div>
        <h1 className="w-full text-center font-bold text-lg">{text}</h1>
      </div>
    </div>
  )
}

export default ArticleBar
