import ArticleBar from 'components/content/ArticleBar'
import ArticlePreview from 'components/content/ArticlePreview'
import VideoPlayer from 'components/content/VideoPlayer'
import { useRouteMatch } from 'react-router'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Spinner from 'components/Spinner'

//REDUX
import { connect } from 'react-redux'
import Pagination from 'react-js-pagination'

const ContentCategory = ({ state }) => {
  const match = useRouteMatch()
  const categoryId = match.params.id

  const [loading, setLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [contentState, setContentState] = useState({
    content: undefined,
    categories: undefined,
    videos: undefined,
    totalPages: 0,
    totalItems: 0,
  })
  const { categories, content, videos, totalItems, totalPages } = contentState

  async function contentLoad() {
    const res2 = await axios.get(process.env.REACT_APP_BASE_URL + '/video', {
      params: { category: categoryId },
    })
    
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/content', {
      params: { category: categoryId, page: page, page_size: 10 },
    })
    const res3 = await axios.get(process.env.REACT_APP_BASE_URL + '/category/' + categoryId)

    setContentState({
      ...contentState,
      content: res.data.data,
      categories: res3.data,
      videos: res2.data,
      totalPages: res.data.pagination.total_pages,
      totalItems: res.data.pagination.total_items,
    })
  }

  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  return loading ? (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  ) : (
    <div>
      <ArticleBar text={categories.title} to="/home/content" />
      <div className="articles-container">
        {content.length !== 0
          ? content.map((article) => {
              return (
                <ArticlePreview
                  key={article.id}
                  image={article.image}
                  header={article.title}
                  kicker={article.subtitle}
                  to={`/home/content/article/${article.id}`}
                />
              )
            })
          : 'Não há conteúdo para essa categoria.'}

        {videos && videos.length > 0
          ? videos.map((video) => {
              console.log('Entrou aqui');
              return <VideoPlayer key={video.id} url={video.link} header={video.title} />
            })
          : ''}
        {
          totalPages > 1 && 
          <Pagination
            totalItemsCount={totalItems}
            activePage={page}
            onChange={(newPage) => {
              setPage(newPage)
              setLoading(true)
            }}
            innerClass="pagination"
            itemClass="pagination-item bg-productPrimary"
            activeClass="pagination-item-active"
          />
        }
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ContentCategory)
