import './profile-user.css'
//Hooks
import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { loadingEnd, loadingStart } from 'state/action-creators'

import RegisterContext from 'context/register/registerContext'

//Componentes
import DropdownInput from 'components/layout/DropdownInput'
import { InputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'
import ProfileMenuBar from 'components/profile/ProfileMenuBar'
import TelephoneInput from 'components/layout/TelephoneInput'
import axios from 'axios'
import Spinner from 'components/Spinner'

const ProfileUser = ({loadingEnd, loadingStart, state}) => {
  const {userData, user, translations} = state

  const ct = translations[user.language_id]
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const [langs, setLangs] = useState()
  const [countries, setCountries] = useState()

  const registerContext = useContext(RegisterContext)
  const { updateUser, updateEmail } = registerContext

  useEffect(() => {
    setFormUserData({
      name: userData.name,
      sex: userData.sex?.id,
      age: userData.age,
      weight_unit: userData.weight_unit?.id,
      height_unit: userData.height_unit?.id,
      waist_unit: userData.waist_unit?.id,
      hip_unit: userData.hip_unit?.id,
      weight: userData.weight,
      height: userData.height,
      waist: userData.waist,
      hip: userData.hip,
      telephone: userData.telephone,
      country_id: userData.country.id,
      language_id: user.language_id,
    })
    setEmailUserData({
      email: userData.email,
    })
  }, []) //eslint-disable-line

  const [formUserData, setFormUserData] = useState({
    name: '',
    sex: -1,
    age: -1,
    weight_unit: -1,
    height_unit: -1,
    waist_unit: -1,
    hip_unit: -1,
    weight: -1,
    height: -1,
    waist: -1,
    hip: -1,
    telephone: '551199999999',
    country_id: -1,
    language_id: -1,
    email: '',
  })
  const [emailUserData, setEmailUserData] = useState({
    email: '',
  })

  const {
    name,
    telephone,
    language_id,
    age,
    weight_unit,
    weight,
    height,
    height_unit,
    sex,
    country_id,
  } = formUserData

  const { email } = emailUserData

  const onChangeNumber = (e) => {
    const target = e.target
    const value = target.value.replace(',', '.')

    switch (target.name) {
      case 'height_unit':
        setFormUserData({
          ...formUserData,
          height: '',
          [target.name]: parseInt(target.value),
        })
        break

      case 'weight_unit':
        setFormUserData({
          ...formUserData,
          weight: '',
          [target.name]: parseInt(target.value),
        })
        break

      case 'height':
        setFormUserData({
          ...formUserData,
          [target.name]: target.value
            .replace(
              height_unit === 20
                ? /\B(?=(\d{2})+(?!\d))/g
                : /\B(?=(\d{2})+(?!\d))/g,
              height_unit === 10 ? '.' : "'"
            )
            .replace(',', '.'),
        })
        break

      default:
        setFormUserData({
          ...formUserData,
          [target.name]: value,
        })
        break
    }
  }

  const onChangeString = (e) => {
    const target = e.target
    if (target.name === 'email')
      return setEmailUserData({ email: target.value.toString() })
    setFormUserData({ ...formUserData, [target.name]: target.value.toString() })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    loadingStart()
    await updateUser(
      { ...formUserData, weight: parseFloat(weight) },
      false,
      true
    )
    await updateEmail({
      username: userData.email,
      email: emailUserData.email,
    })
    loadingEnd()
    history.push('/home/profile')
  }

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/language', {
      params: { language_id: user?.language_id },
    })
    const res1 = await axios.get(process.env.REACT_APP_BASE_URL + '/country', {
      params: { language_id: user?.language_id },
    })

    setLangs(res.data)
    setCountries(res1.data)
  }
  
  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading)
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  )

  return (
    <form onSubmit={onSubmit} className="flex flex-col items-center">
      <ProfileMenuBar to="/home/profile" text={ct?.profileMenuUserData} />
      <div className="w-product">
        <div className="grid-inputs">
          <InputField
            label={ct?.regLabelName}
            name="name"
            onChange={onChangeString}
            value={name}
          />

          <TelephoneInput
            country={'br'}
            value={telephone}
            onChange={(telephone) =>
              setFormUserData({ ...formUserData, telephone })
            }
            label={ct?.regLabelPhone}
            invalidLabel={ct?.regLabelPhoneInvalid}
          />

          <InputField
            onChange={onChangeString}
            value={email}
            name="email"
            label={ct?.regLabelEmail}
            invalidLabel={ct?.regLabelEmailInvalid}
          />

          <div className="flex">
            <h1 className="label-left">{ct?.regChooseCountry}</h1>
            <div className="input-right">
              <DropdownInput
                iconFill="#dddddd"
                className="sec"
                name="country_id"
                onChange={onChangeNumber}
                value={country_id}
                options={countries.map((option) => [
                  parseInt(option.id),
                  option.name,
                ])}
              />
            </div>
          </div>

          <div className="flex">
            <h1 className="label-left">{ct?.regChooseLang}</h1>
            <div className="input-right">
              <DropdownInput
                iconFill="#dddddd"
                className="sec"
                name="language_id"
                onChange={onChangeNumber}
                value={language_id}
                options={langs.map((option) => [
                  parseInt(option.id),
                  option.name,
                ])}
              />
            </div>
          </div>

          <div className="flex">
            <h1 className="label-left">{ct?.regChooseGender}</h1>
            <div className="input-right">
              <DropdownInput
                iconFill="#dddddd"
                className="sec"
                name="sex"
                onChange={onChangeNumber}
                value={sex}
                options={[
                  [10, `${ct?.genderOptionMale}`],
                  [20, `${ct?.genderOptionFemale}`],
                ]}
              />
            </div>
          </div>

          <div className="flex relative">
            <h1 className="label-left">{ct?.regChooseAge}</h1>
            <InputField
              className="noypad"
              name="age"
              onChange={onChangeNumber}
              value={age}
              type="tel"
              maxLength={3}
            />
            <p
              className="absolute text-gray-400"
              style={{
                top: '50%',
                marginTop: '-12px',
                left: '55%',
                pointerEvents: 'none',
              }}
            >
              {ct?.yearsOld}
            </p>
          </div>

          <div className="flex">
            <h1 className="label-left">{ct?.regChooseWeight}</h1>
            <div className="flex w-full">
              <InputField
                className="noypad w-14 mr-2"
                name="weight"
                onChange={onChangeNumber}
                value={weight}
              />
              <div className="input-right ml-3">
                <DropdownInput
                  iconFill="#dddddd"
                  className="sec"
                  name="weight_unit"
                  onChange={onChangeNumber}
                  value={weight_unit}
                  options={[
                    [10, `${ct?.heightUnitOptionKg}`],
                    [20, `${ct?.heightUnitOptionLb}`],
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="flex">
            <h1 className="label-left">{ct?.regChooseHeight}</h1>
            <div className="flex w-full">
              <InputField
                maxLength={4}
                type="tel"
                className="noypad"
                name="height"
                onChange={onChangeNumber}
                value={height}
              />
              <div className="input-right ml-3">
                <DropdownInput
                  iconFill="#dddddd"
                  className="sec"
                  name="height_unit"
                  onChange={onChangeNumber}
                  value={height_unit}
                  options={[
                    [10, `${ct?.heightUnitOptionM}`],
                    [20, `${ct?.heightUnitOptionFt}`],
                  ]}
                />
              </div>
            </div>
          </div>
          <Button className="main-button mt-5" text={ct?.profileSave} />
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => ({
  state: state.general,
})

export default connect(mapStateToProps, { loadingEnd, loadingStart })(
  ProfileUser
)
