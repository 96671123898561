import './calendar.css'
import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import TrackerDay from 'components/panel/TrackerDay'
import { ReactComponent as NextIcon } from 'assets/down.svg'
import { ReactComponent as ClockIcon } from 'assets/clock.svg'

import axios from 'axios'
import Spinner from 'components/Spinner'

interface history {
  name: string
  goal: { id: string; name: string }
  week: {
    total_time: number
    sessions: {
      id: number
      date: string
      duration: number
      total_exercises: string
      finished_exercises: string
      finished_percentage: number
    }[]
  }
}

interface session {
  date: string
  duration: number
  finished_exercises: string
  finished_percentage: number
  id: number
  total_exercises: string
}

function Calendar(props: { state: any }) {
  const state = props.state
  const langContent = state.translations

  const ct = langContent[state.user.language_id as '1']
  const today = new Date()

  const [calLoading, setcalLoading] = useState<boolean>(true)
  const [history, setHistory] = useState<(null | history)[]>([null, null, null])
  const [calendarState, setCalendarState] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  })
  const { month, year } = calendarState

  function getDaysInMonth(month: number, year: number) {
    return new Date(year, month + 1, 0).getDate()
  }

  const loadHistory = async (_month: number, _year: number) => {
    const params = {
      0: { month: _month, year: _year },
      1: { month: _month + 1, year: _year },
      2: { month: _month + 2, year: _year },
    }

    let data: any = []

    for (let i = 0; i < 3; i++) {
      await axios
        .get(process.env.REACT_APP_BASE_URL + '/user-training/history', {
          params: params[i as 0 | 1 | 2],
        })
        .then((res) => {
          data.push(res.data)
        })
        .catch((err) => {
          // if (err.response && err.response.status === 404) console.clear()

          data.push(null)
        })
    }
    return data
  }

  useEffect(() => {
    setcalLoading(true)
    loadHistory(month, year).then((res) => {
      setHistory(res)
      setcalLoading(false)
    })
  }, [month, year])

  function goNextMonth(actualMonth: number) {
    let newMonth = actualMonth + 1

    if (newMonth === 12) {
      let newYear = year + 1
      setCalendarState({ ...calendarState, year: newYear, month: 1 })
    } else {
      setCalendarState({ ...calendarState, month: newMonth })
    }
  }

  function goPreviousMonth(actualMonth: number) {
    let newMonth = actualMonth - 1

    if (newMonth === -1) {
      let newYear = year - 1
      setCalendarState({ ...calendarState, year: newYear, month: 11 })
    } else {
      setCalendarState({ ...calendarState, month: newMonth })
    }
  }

  const filterDays = (fullDate: string): session | undefined => {
    if (history[1] === null) return

    let dateDataArr = history[1]?.week?.sessions.filter((day) => day.date === fullDate)

    if (dateDataArr.length > 1) {
      let totalSeconds = dateDataArr.reduce((acc, cur) => {
        return acc + cur.duration
      }, 0)
      let totalPercentage = dateDataArr.reduce((acc, cur) => {
        return acc + cur.finished_percentage
      }, 0)
      let calculatePercentage = totalPercentage / dateDataArr.length
      return {
        date: '',
        duration: totalSeconds,
        finished_exercises: '',
        finished_percentage: calculatePercentage,
        id: -1,
        total_exercises: '',
      }
    } else {
      let dateData = history[1]?.week?.sessions.filter((day) => day.date === fullDate)[0]
      return dateData
    }
  }

  const dayTrackers: JSX.Element[] = []
  const daysInMonth = getDaysInMonth(month, year) + 1

  function renderDayTrackers() {
    const todayFullDate = new Date().toISOString().substring(0, 10)
    for (let i = 1; i < daysInMonth; i++) {
      //weekday is zero-based, sunday = 0
      let weekday = new Date(year, month, i).getDay()
      if (weekday === 0) weekday = 7
      let fullDate = new Date(year, month, i).toISOString().substring(0, 10)

      if (i === 1) {
        if (weekday !== 1)
          for (let i = 0; i < weekday - 1; i++) {
            dayTrackers.push(<div key={`${i}a`} />)
          }
      }

      let dateData = filterDays(fullDate)

      dayTrackers.push(
        <TrackerDay
          key={fullDate}
          barSize={dateData ? (Math.ceil(dateData.duration / 60) * 100) / 60 : 0}
          weekday={weekday.toString() as '1' | '2' | '3' | '4' | '5' | '6' | '7'}
          monthDay={i.toString()}
          active={fullDate === todayFullDate}
          minutes={dateData ? Math.ceil(dateData.duration / 60) : 0}
        />
      )
    }
  }
  renderDayTrackers()

  const monthName = (index: string): string => ct[`panelMonth${index}`]

  return (
    <Fragment>
      {calLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="calendar-wrapper w-product">
            <div className="flex justify-between w-full items-center">
              <div
                className={history[0] !== null ? 'calendar-button' : 'calendar-button disabled'}
                onClick={() => {
                  history[0] !== null && goPreviousMonth(month)
                }}
              >
                <NextIcon className="calendar-prev-arrow" fill={history[0] !== null ? ' #ff6900' : '#aaa'} />
              </div>
              <h1 className="calendar-month-title">
                {monthName(month.toString())} {ct?.of} {year}
              </h1>
              <div
                className={history[2] !== null ? 'calendar-button' : 'calendar-button disabled'}
                onClick={() => {
                  history[2] !== null && goNextMonth(month)
                }}
              >
                <NextIcon className="calendar-next-arrow" fill={history[2] !== null ? ' #ff6900' : '#aaa'} />
              </div>
            </div>

            <div className="my-4">
              <p className="calendar-time-aux">{ct?.panelTotalTime}</p>
              <div className="flex justify-center items-center">
                <ClockIcon fill="#17D85C" className="mr-2" />
                <p className="calendar-time">
                  {history[1] !== null &&
                    (Math.floor(history[1]?.week?.total_time / 3600) === 0
                      ? ''
                      : `${Math.floor(history[1]?.week?.total_time / 3600)}h `)}
                  {history[1] === null
                    ? '0:'
                    : Math.floor(history[1]?.week?.total_time / 60) % 60 === 0
                    ? ''
                    : `${Math.floor(history[1]?.week?.total_time / 60) % 60}m `}
                  {history[1] === null
                    ? '00'
                    : `${history[1]?.week?.total_time % 60 < 10 ? 0 : ''} ${history[1]?.week?.total_time % 60}s`}
                </p>
              </div>
            </div>
          </div>

          <div className="calendar-week w-product">{dayTrackers.splice(0, 7)}</div>
          <div className="calendar-week w-product">{dayTrackers.splice(0, 7)}</div>
          <div className="calendar-week w-product">{dayTrackers.splice(0, 7)}</div>
          <div className="calendar-week w-product">{dayTrackers.splice(0, 7)}</div>
          {dayTrackers.length !== 0 && <div className="calendar-week w-product">{dayTrackers.splice(0, 7)}</div>}
        </Fragment>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(Calendar)
