import { ChangeEvent, MouseEventHandler, useState, FormEvent, Fragment } from 'react'
import './styles.css'

import { connect } from 'react-redux'
import { login } from 'state/action-creators'

//Componentes
import { InputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'

import { Link } from 'react-router-dom'

interface LoginScreenProps {
  onClick: MouseEventHandler
  login: (loginData?: any) => Promise<void>
  state: any
}

const LoginScreen = (props: LoginScreenProps) => {
  const { onClick, login: loginAction } = props
  const { user, error, translations } = props.state
  const langContent = translations

  const ct = langContent[user.language_id as '1']

  const [loginData, setLoginData] = useState({
    login: '',
    password: '',
  })
  const { login, password } = loginData

  const onChange = (e: ChangeEvent) => {
    const name = (e.target as HTMLInputElement).name
    const value = (e.target as HTMLInputElement).value
    setLoginData({ ...loginData, [name]: value })
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    loginAction(loginData)
  }

  return (
    <Fragment>
      <div className="ath-container mb-5">
        <h1 className="mt-5 main-title w-full w-product">{ct?.loginTitle}</h1>
        <form onSubmit={onSubmit} className="grid-inputs w-product">
          <InputField
            value={login}
            onChange={onChange}
            name="login"
            label={ct?.loginLabelEmail}
            isValid={!error}
            invalidLabel="Usuário ou senha inválidos."
          />
          <InputField
            type={'password'}
            value={password}
            onChange={onChange}
            name="password"
            label={ct?.loginLabelPass}
          />

          <Button text={ct?.loginSend} />
        </form>
        <Link className="-mt-2 font-semibold text-productPrimary" to="/forgot-password">
          {ct?.loginResetPass}
        </Link>
        <div className="my-10 flex items-center">
          <p className="text-gray-400">{`${ct?.loginRegText} `}</p>
          <button onClick={onClick} name="register" className=" register text-productPrimary font-semibold w-auto">
            {ct?.loginReg}
          </button>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { login })(LoginScreen)
