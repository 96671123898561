import { useReducer } from 'react'
import {
  COMPOSE_NEW_USER,
  COMPOSE_USER_INFO,
  CLEAR_STATE,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  COMPOSE_USER_EQUIPS,
  UPDATE_USER_EQUIPS_SUCCESS,
  UPDATE_USER_EQUIPS_FAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_FAIL,
} from '../types'

import RegisterContext from './registerContext'

import { connect } from 'react-redux'
import { loadUser, createWorkout, loadUserData, loadProgress } from 'state/action-creators'

import registerReducer from './registerReducer'
import axios from 'axios'

const RegisterState = (props) => {
  const { loadUser, createWorkout, loadUserData, loadProgress } = props

  const initialState = {
    newUser: {},
    userInfo: {},
    userEquips: [],
    registerError: null,
    updateError: null,
    equipsError: null,
    emailError: null,
  }

  const [state, dispatch] = useReducer(registerReducer, initialState)

  const composeNewUser = (regData) => dispatch({ type: COMPOSE_NEW_USER, payload: regData })
  const composeUserInfo = (regData) => dispatch({ type: COMPOSE_USER_INFO, payload: regData })
  const composeUserEquips = (regData) => {
    dispatch({ type: COMPOSE_USER_EQUIPS, payload: regData })
  }

  const registerNewUser = async () => {
    const config = {
      'Content-Type': 'application/json',
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/register`, state.newUser, config)
      dispatch({ type: REGISTER_SUCCESS, payload: res.data })

      await loadUser()

      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/profile/update`,
        {
          ...state.userInfo,
          language_id: parseInt(props.state.user.language_id),
          country_id: parseInt(props.state.userData.country.id),
        },
        config
      )

      await loadUser()

      await axios.put(`${process.env.REACT_APP_BASE_URL}/user-equipment/set`, state.userEquips, config)

      await loadUser()

      await createWorkout()

      await loadUser()

      await loadProgress()

      dispatch({ type: CLEAR_STATE })
    } catch (error) {
      dispatch({ type: REGISTER_FAIL, payload: error })
    }
  }

  const updateUser = async (userData, setNewWorkout, reloadUserData) => {
    const config = {
      'Content-Type': 'application/json',
    }
    try {
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/profile/update`, userData, config)
      if (setNewWorkout) await createWorkout()
      if (reloadUserData) {
        await loadUserData()
        await loadUser()
      }

      dispatch({ type: UPDATE_USER_SUCCESS })
    } catch (error) {
      dispatch({ type: UPDATE_USER_FAIL, payload: error })
    }
  }

  const updateEquips = async (equipsData, setNewWorkout, reloadUserData) => {
    const config = {
      'Content-Type': 'application/json',
    }
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/user-equipment/set`, equipsData, config)

      if (reloadUserData) await loadUserData()

      dispatch({ type: UPDATE_USER_EQUIPS_SUCCESS })
    } catch (error) {
      dispatch({ type: UPDATE_USER_EQUIPS_FAIL, payload: error })
    }
  }

  const updateEmail = async (emailData) => {
    const config = {
      'Content-Type': 'application/json',
    }
    try {
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/user/update-email`, emailData, config)
      await loadUserData()

      dispatch({ type: UPDATE_USER_EMAIL_SUCCESS })
    } catch (error) {
      dispatch({ type: UPDATE_USER_EMAIL_FAIL, payload: error })
    }
  }

  const clearState = () => {
    dispatch({ type: CLEAR_STATE })
  }

  return (
    <RegisterContext.Provider
      value={{
        state,
        composeNewUser,
        registerNewUser,
        composeUserInfo,
        updateUser,
        clearState,
        composeUserEquips,
        updateEquips,
        updateEmail,
      }}
    >
      {props.children}
    </RegisterContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  loadUser,
  createWorkout,
  loadUserData,
  loadProgress,
})(RegisterState)
