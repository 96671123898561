import './navbar.css'
import { MouseEventHandler } from 'react'
import { connect } from 'react-redux'
import { showTrainer, startingSession } from 'state/action-creators'
import { NavLink, useHistory } from 'react-router-dom'

//Components
import { ReactComponent as ChartIcon } from 'assets/chart.svg'
import { ReactComponent as CheckIcon } from 'assets/check.svg'
import { ReactComponent as ContentIcon } from 'assets/content.svg'
import { ReactComponent as ProfileIcon } from 'assets/profile.svg'


interface NavbarProps {
  onClick?: MouseEventHandler
  state: any
  showTrainer: () => void
  startingSession: () => void
}

const Navbar = (props: NavbarProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const history = useHistory()
  const { userProgress, sessionStatus } = state
  const { showTrainer, startingSession } = props

  const isTrainingComplete = () => {
    if (userProgress) {
      return (userProgress.expected_sessions === userProgress.finished_trainings_count) && (userProgress.finished_all_trainings === false)
    }
  }

  const onClick = () => {
    if (!isTrainingComplete()) {
      document.body.setAttribute('style', 'position: fixed')
      if (sessionStatus === 'stopped') startingSession()
      showTrainer()
    } else {
      console.log('TESTEEEEEEEE');
      console.log(userProgress.finished_trainings_count);
      history.push('/home/routine')
    }
  }

  return (
    <div className="navbar-container">
      <div className={sessionStatus === 'stopped' ? 'navbar' : 'navbar alt'}>
        <NavLink
          activeClassName="selected"
          to="/home/panel"
          className="nav-element"
        >
          <ChartIcon className="nav-icon" fill="white" />
          <span className="nav-element-text">{ct?.navPanel}</span>
        </NavLink>

        <NavLink
          activeClassName="selected"
          to="/home/routine"
          className="nav-element"
        >
          <CheckIcon className="nav-icon" fill="white" />
          <span className="nav-element-text">{ct?.navRoutine}</span>
        </NavLink>

        <span className="nav-spacer"></span>

        <NavLink
          activeClassName="selected"
          to="/home/content"
          className="nav-element"
        >
          <ContentIcon className="nav-icon" fill="white" />
          <span className="nav-element-text">{ct?.navContent}</span>
        </NavLink>

        <NavLink
          activeClassName="selected"
          to="/home/profile"
          className="nav-element"
        >
          <ProfileIcon className="nav-icon" fill="white" />
          <span className="nav-element-text">{ct?.navProfile}</span>
        </NavLink>
      </div>
      <button
        onClick={onClick}
        className={
          sessionStatus === 'stopped' ? 'nav-trainer' : 'nav-trainer alt'
        }
      >
        <CheckIcon fill="white" />
        <span className="text-white text-xs font-bold">
          {sessionStatus === 'stopped' ? ct?.navTrainer : ct?.navTrainerOn}
        </span>
      </button>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { showTrainer, startingSession })(
  Navbar
)
