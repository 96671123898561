import React, { useEffect } from 'react'
import Loading from 'components/Loading'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  loadProgress,
  loadUserData,
  loadingEnd,
  loadingStart,
  loadUser,
  loadTranslations,
  checkForSession,
} from 'state/action-creators'

const Loader = (props: any) => {
  const {
    state,
    loadProgress,
    loadUserData,
    loadingEnd,
    loadingStart,
    loadUser,
    checkForSession,
    loadTranslations,
  } = props

  const { isAuth, access_token, user } = state

  const history = useHistory()

  useEffect(() => {
    if (isAuth) secondLoad()
    else history.push('/login')
  }, [isAuth, user]) //eslint-disable-line

  useEffect(() => {
    firstLoad()
  }, [access_token]) //eslint-disable-line

  const firstLoad = async () => {
    loadingStart()
    await loadUser()
    await loadTranslations()
  }

  const secondLoad = async () => {
    loadingStart()
    await loadUserData()
    await loadProgress()
    await checkForSession()
    loadingEnd()
    history.push('/home/panel')
  }

  return <Loading />
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  loadProgress,
  loadUserData,
  loadingEnd,
  loadingStart,
  loadUser,
  checkForSession,
  loadTranslations,
})(Loader)
