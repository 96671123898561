import './exercises-view.css'
import Exercises from 'components/routine/Exercises'
import WorkoutBar from 'components/panel/WorkoutBar'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Spinner from 'components/Spinner'

interface ExercisesViewProps {
  date?: number
  id?: number
  state: any
}

const ExercisesView = (props: ExercisesViewProps) => {
  const [loading, setLoading] = useState(true)
  
  const [exercises, setExercises] = useState<{
    id: string
    name: string
    description: string
    series: string
    duration: string
    duration_type: string
    exercise_id: string
    status: boolean
    image: string
  }[]>()

  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/user-training/exercises', {
      params: { language_id: state.user?.language_id, id: props.id },
    })

    setExercises(res.data)
  }

  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading || !exercises)
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  )

  

  return (
    <div className="exercises-view">
      <h1 className="w-product exercise-view-title">
        {ct?.exerciseTitle}{' '}
        {props.date ? ct[`routineWeekday${props.date}`] : ct?.today}
      </h1>
      <div className="exercise-view-bar">
        <WorkoutBar />
      </div>
      <Exercises hideCheckbox data={exercises} />
    </div>
  )
}
const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ExercisesView)
