//Hooks
import { ChangeEvent, FormEvent, useState } from 'react'
import { connect } from 'react-redux'

//Componentes
import { BigInputField, SmallInputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'
import formatarMedidas from 'utils/formatarMedidas'

interface FormWeightProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  state: any
}

const FormWeight = (props: FormWeightProps) => {
  const { next, getData, buttonText } = props
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const [regData, setRegData] = useState({
    weight: '',
    weight_unit: 10,
  })

  const { weight, weight_unit } = regData

  const check = (x: any) => {
    let check = parseFloat(x)
    return check > 1
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    if (target.name === 'weight_unit') {
      setRegData({
        ...regData,
        weight: '',
        [target.name]: parseFloat(target.value),
      })
      return
    } else
      setRegData({
        ...regData,
        [target.name]: formatarMedidas(target.value),
      })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (check(weight)) {
      getData({ weight: Number(weight), weight_unit: weight_unit })
      if (next) next()
    }
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="relative grid-inputs">
        <BigInputField value={weight} name="weight" onChange={onChange} type="tel" maxLength={6} placeholder="0" />
        <SmallInputField
          onChange={onChange}
          value={weight_unit}
          name="weight_unit"
          type="text"
          className="sm-input"
          options={[
            [10, ct?.heightUnitOptionKg],
            [20, ct?.heightUnitOptionLb],
          ]}
        />
      </div>
      <Button className="main-button mt-5" disabled={!check(weight)} text={buttonText} />
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormWeight)
