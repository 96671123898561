import ExerciseElement from './ExerciseElement'

interface ExercisesProps {
  data: {
    id: string
    name: string
    description: string
    series: string
    duration: string
    duration_type: string
    exercise_id: string
    status: boolean
    image: string
  }[]
  hideCheckbox?: boolean
  onClick?: Function
}

const Exercises = (props: ExercisesProps) => {
  const { data, hideCheckbox, onClick } = props

  return (
    <div className="ath-container">
      {data &&
        data.map((exercise) => (
          <ExerciseElement
            onClick={onClick && onClick}
            key={exercise.id}
            id={exercise.id}
            name={exercise.name}
            series={exercise.series}
            duration={exercise.duration}
            duration_type={exercise.duration_type}
            status={exercise.status}
            image={exercise.image}
            hideCheckbox={hideCheckbox}
          />
        ))}
    </div>
  )
}

export default Exercises
