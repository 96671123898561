import { ReactComponent as DotIcon } from 'assets/dot.svg'
import { ReactComponent as BrandIcon } from 'assets/brand.svg'
import './progress-bar.css'

interface ProgressBarProps {
  value: number
  max: number
}

const ProgressBar = (props: ProgressBarProps) => {
  const { value, max } = props

  let lis = []
  for (let i = 0; i < max; i++) lis.push(i)

  const renderLi = (i: number) => {
    return (
      <li key={i}>
        <DotIcon fill={value === i ? '#ff6900' : '#DDDDDD'} />
      </li>
    )
  }

  return (
    <div className="flex w-full justify-center">
      <ol className="progress-bar">
        {value > 8 ? (
          <BrandIcon fill="#ff6900" className="h-2vh" />
        ) : (
          lis.map((e) => renderLi(e))
        )}
      </ol>
    </div>
  )
}

export default ProgressBar
