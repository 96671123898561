import './inputField.css'
import { useState, useEffect } from 'react'
import { ReactComponent as ShowPassIcon } from '../../assets/showpass.svg'
import { ChangeEventHandler } from 'react'

interface InputFieldProps {
  name: string
  label?: string
  type?: string
  value: string
  onChange: ChangeEventHandler
  className?: string
  isValid?: boolean
  invalidLabel?: string
  maxLength?: number
}

// EXPORT
export const InputField = (props: InputFieldProps) => {
  const {
    name,
    label,
    type,
    value,
    onChange,
    className,
    invalidLabel,
    isValid,
    maxLength,
  } = props

  //Label animation
  const [isActive, setIsActive] = useState(false)
  const handleTextChange = (text: string): void => {
    setIsActive(text !== '' ? true : false)
  }
  useEffect(() => {
    if (value !== '') setIsActive(true)
  }, [value])

  //Show password icon
  const [showPass, setshowPass] = useState(false)
  interface PassToggleProps {
    enabled: boolean
  }
  const PassToggle = (props: PassToggleProps) => {
    const { enabled } = props
    if (enabled) {
      return (
        <ShowPassIcon
          onClick={() => setshowPass(!showPass)}
          className="show-pass-wrapper"
          fill={showPass ? '#FF6900' : '#DDDDDD'}
        />
      )
    } else return null
  }

  return (
    <div id="input" className="relative">
      <input
        name={name}
        value={value}
        type={showPass ? 'text' : type}
        className={`input-field ${className ? className : ''} ${
          isValid ? '' : 'invalid-input'
        }`}
        onChange={onChange}
        onInput={(e) => {
          handleTextChange((e.target as HTMLInputElement).value)
        }}
        autoComplete="nope"
        maxLength={maxLength}
      />
      <label
        htmlFor={name}
        className={`input-label ${isActive ? 'active' : ''} ${
          isValid ? '' : 'invalid-label'
        }`}
      >
        {isValid ? label : invalidLabel}
      </label>
      <PassToggle enabled={type === 'password'} />
    </div>
  )
}

InputField.defaultProps = {
  type: 'text',
  isValid: true,
}

// EXPORT

interface BigInputFieldProps {
  name: string
  label?: string
  type: string
  value: string | number
  onChange: ChangeEventHandler
  variant?: string
  placeholder: string
  maxLength: number
}

export const BigInputField = (props: BigInputFieldProps) => {
  const { name, label, type, value, onChange, placeholder, maxLength } = props
  return (
    <div id="input" className="relative">
      <label htmlFor={name} className="big-input-label">
        {label}
      </label>
      <input
        placeholder={placeholder}
        value={value}
        name={name}
        type={type}
        maxLength={maxLength}
        className="big-input-field"
        onChange={onChange}
      />
    </div>
  )
}

BigInputField.defaultProps = {
  type: 'text',
}

// EXPORT

interface SmallInputFieldProps {
  name: string
  value: string | number
  onChange: ChangeEventHandler
  options: [value: number, label: string][]
  className?: string
}

export const SmallInputField = ({ onChange, value, name, options, className }: SmallInputFieldProps) => {
  return (<>
    <select onChange={onChange} value={value} name={name} className={className}>
      {options.map((option, index) => {
        return (
          <option key={index} value={option[0]}>
            {option[1]}
          </option>
        )
      })}
    </select>
        <div className='sm-input-handle'></div>
  </>
  )
}

SmallInputField.defaultProps = {
  type: 'text',
  className: 'sm-input',
}

// EXPORT
