import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Spinner from 'components/Spinner'

import ProfileMenuBar from 'components/profile/ProfileMenuBar'

const ProfilePolicies = (props: { state: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const [loading, setLoading] = useState(true)
  const [policies, setPolicies] = useState([{ text: '' }])

  async function polLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/privacy-policy', {
      params: { language_id: state.user.language_id },
    })
    setPolicies(res.data)
  }

  useEffect(() => {
    if (loading) polLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  return loading ? (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  ) : (
    <div className="ath-container">
      <ProfileMenuBar text={ct?.profileMenuPrivacy} to="/home/profile" />
      <div className="w-product policies" dangerouslySetInnerHTML={{ __html: policies[0].text }}></div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ProfilePolicies)
