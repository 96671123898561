import React, { useEffect } from 'react'
import './styles.css'
import { connect } from 'react-redux'
import { ReactComponent as IosConf } from 'assets/ios-conf.svg'
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg'

function PWAModalIOS({ state }: any) {
  const { loading } = state

  const [isVisible, setVisibleState] = React.useState(false)

  function iOS() {
    return (
      (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform
      ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) &&
      navigator.userAgent.includes('Mac')
    )
  }

  const isSafari = () =>
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1

  const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches

  useEffect(() => {
    if (!loading && iOS() && !isInStandaloneMode() && isSafari()) {
      setTimeout(() => setVisibleState(true), 1000)
    }
  }, [loading])

  if (!isVisible) return null

  return (
    <div className="pwa-modal-wrapper">
      <div className="pwa-modal">
        <div
          onClick={() => {
            setVisibleState(false)
          }}
          className="py-2 close-pwa-modal"
        >
          <span>Fechar e continuar sem instalar</span>
          <ArrowRight />
        </div>
        <div className="pwa-modal-top">
          <p>Instale nosso app agora e leve seu treino com você</p>
        </div>

        <div className="pwa-modal-bottom">
          <span>Clique em </span> <IosConf style={{ marginLeft: '5px', marginRight: '5px' }}></IosConf>{' '}
          <span>
            depois em <strong>Adicionar à Tela de Início</strong>
          </span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(PWAModalIOS)
