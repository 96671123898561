import React, { useEffect, useLayoutEffect, useState } from 'react'
import './styles.css'
import { connect } from 'react-redux'
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg'
import Button from 'components/layout/Button'

function PWAModalAndroid({ state }: any) {
  const { loading } = state
  const [isVisible, setVisibleState] = useState(false)
  const [deferredPrompt, setdeferredPrompt] = useState<any>()

  function iOS() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }
  const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches

  function setupPrompt() {
    console.log('CHAMOU');
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('GUARDOU');
      console.log(e);
      e.preventDefault()
      console.log(e);
      setdeferredPrompt(e)
    })
  }

  useLayoutEffect(() => {
    setupPrompt()
  }, [])

  useEffect(() => {
    console.log('DEFERRED1');
    console.log(deferredPrompt);
    if (!loading && !iOS() && !isInStandaloneMode() && deferredPrompt != null) {
      console.log('ta ativando');
      setTimeout(() => setVisibleState(true), 1000)
    }
  }, [loading, deferredPrompt])

  if (!isVisible) return null

  return (
    <div className="pwa-modal-wrapper">
      <div className="pwa-modal">
        <div
          onClick={() => {
            setVisibleState(false)
          }}
          className="py-2 close-pwa-modal"
        >
          <span>Fechar e continuar sem instalar</span>
          <ArrowRight />
        </div>
        <div className="pwa-modal-top-android">
          <p>Instale nosso app agora e leve seu treino com você</p>
        </div>

        <div className="pwa-modal-bottom-android">
          <Button
            marginTopClass="mt-0"
            text="Instalar app agora"
            onClick={() => {
              console.log('ativar');
              deferredPrompt?.prompt()
              setVisibleState(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(PWAModalAndroid)
