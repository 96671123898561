import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import RegisterContext from 'context/register/registerContext'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { loadingEnd, loadingStart, createWorkout } from 'state/action-creators'

//Componentes
import Drawer from 'react-bottom-drawer'
import ProfileMenuBar from 'components/profile/ProfileMenuBar'
import FormObjective from 'components/forms/FormObjective'
import Button from 'components/layout/Button'

//Styles and content
import './profile-objective.css'
import { ReactComponent as MegaphoneIcon } from 'assets/megaphone.svg'

interface ProfileObjectiveProps {
  state: any
  loadingEnd: () => void
  loadingStart: () => void
  createWorkout: () => void
}

const ProfileObjective = (props: ProfileObjectiveProps) => {
  const history = useHistory()
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const { loadingEnd, loadingStart, createWorkout } = props
  const { userData } = state

  const registerContext = useContext(RegisterContext)
  const { updateUser } = registerContext

  const [objData, setObjData] = useState({ goal_id: -1 })

  useEffect(() => {
    setObjData({ ...objData, goal_id: Number(userData.goal?.id) })
  }, []) //eslint-disable-line

  const getData = (data: {}): void => {
    setObjData({ ...objData, ...data })
    togglePopup()
  }

  const [showPopup, setShowPopup] = useState(false)
  const togglePopup = (): void => {
    setShowPopup(!showPopup)
  }

  const onSubmit = async () => {
    loadingStart()
    await updateUser(objData, true, true)
    await createWorkout()
    loadingEnd()
    history.push('/home/profile')
  }

  return (
    <div className="ath-container">
      <ProfileMenuBar to="/home/profile" text={ct?.profileMenuObjective} />
      <h1 className="w-product pt-5">{ct?.profileMenuObjectiveText}</h1>
      <FormObjective getData={getData} buttonText={ct?.profileSave} initialState={objData}>
        <div className="objective-warning">
          <div className="text-inherit flex items-center">
            <MegaphoneIcon className="h-10 mr-4" fill="#12BEF6" />
            <span className="font-bold text-inherit">{ct?.profileMenuObjectiveAttention}</span>
          </div>
          <p className="text-sm">
              {ct?.profileMenuObjectiveAttentionText}<strong> {ct?.profileMenuObjectiveAttentionTextComplement}</strong>
          </p>
        </div>
      </FormObjective>

      <Drawer isVisible={showPopup} onClose={() => togglePopup()}>
        <div className="ath-container">
          <div className="w-product">
            <h1 className="my-5 main-title w-full pb-5">{ct?.profileMenuObjectiveWarn}</h1>
            <h1 className="text-sm text-gray-500">{ct?.profileMenuObjectiveWarnAux}</h1>
            <h1 className="text-black font-semibold text-sm mt-5 mb-16">{ct?.profileMenuObjectiveWarnAux2}</h1>
            <div className="grid-inputs">
              <Button onClick={onSubmit} text={ct?.profileMenuObjectiveWarnYes} />
              <Link to="/home/profile">
                <button name="objNo" className=" text-productPrimary font-semibold w-full">
                  {ct?.profileMenuObjectiveWarnNo}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  loadingEnd,
  loadingStart,
  createWorkout,
})(ProfileObjective)
