import {
  USER_DATA_LOADED,
  GOALS_LOADED,
  LOADING_END,
  EQUIPS_LOADED,
  LOADING_START,
  CLEAR_STATE,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  LOGIN_SUCCESS,
  PROGRESS_LOADED,
  SHOW_TRAINER,
  HIDE_TRAINER,
  SESSION_STARTED,
  SESSION_STOPPED,
  SESSION_PAUSED,
  SESSION_STARTING,
  STORE_OPENED,
  STORE_CLOSED,
  WORKOUT_CREATED,
  SESSION_CREATED,
  SESSION_UPDATED,
  TOKEN_CHECK,
  LANG_CHANGED,
  COUNTRY_CHANGED,
  TRANSLATIONS_LOADED,
  SESSION_LOADED,
} from '../types'

const initialState = {
  access_token: localStorage.getItem('access_token'),
  isAuth: null,
  user: {
    id: null,
    language_id: 1,
  },
  translations: [],
  error: null,
  content: [],
  userData: { country: { id: 1 } },
  userProgress: {},
  equips: {},
  videos: [],
  contentCategories: [],
  loading: true,
  isTrainerVisible: false,
  sessionStatus: 'stopped',
  isStoreOpen: false,
  currentExercises: [],
  session: {
    id: null,
    date: null,
    time: null,
    expected_exercises: null,
    finished_exercises: null,
    exercises: [],
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isAuth: true,
        user: action.payload,
        error: null,
      }
    case LANG_CHANGED:
      return {
        ...state,
        user: {
          id: state.user.id,
          language_id: action.payload,
        },
      }
    case COUNTRY_CHANGED:
      return {
        ...state,
        userData: { ...state.userData, country: { id: action.payload } },
      }

    case AUTH_ERROR:
      // global.window.location = '/'
      // global.window.location.reload(true)
      localStorage.removeItem('access_token')
      return initialState

    case LOGIN_SUCCESS:
      localStorage.setItem('access_token', action.payload.access_token)
      return {
        ...state,
        access_token: action.payload.access_token,
        error: null,
        isAuth: true,
      }

    case LOGIN_FAIL:
      localStorage.removeItem('access_token')
      return {
        ...state,
        token: null,
        isAuth: false,
        user: {
          id: null,
          language_id: 1,
        },
        error: action.payload.message,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    case LOGOUT:
      localStorage.removeItem('access_token')
      return {
        ...state,
        access_token: null,
        isAuth: false,
      }

    case TRANSLATIONS_LOADED:
      return {
        ...state,
        translations: { ...state.translations, ...action.payload },
      }

    case USER_DATA_LOADED:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      }
    case EQUIPS_LOADED:
      return {
        ...state,
        equips: { ...state.equips, ...action.payload },
      }
    case GOALS_LOADED:
      return {
        ...state,
        goals: [...action.payload],
      }
    case PROGRESS_LOADED:
      return {
        ...state,
        userProgress: { ...state.userProgress, ...action.payload },
      }
    case LOADING_END:
      return {
        ...state,
        loading: false,
      }
    case LOADING_START:
      return {
        ...state,
        loading: true,
      }
    case CLEAR_STATE:
      return initialState

    case SHOW_TRAINER:
      return {
        ...state,
        isTrainerVisible: true,
      }
    case HIDE_TRAINER:
      return {
        ...state,
        isTrainerVisible: false,
      }
    case SESSION_STARTED:
      return {
        ...state,
        sessionStatus: 'started',
      }
    case SESSION_STOPPED:
      return {
        ...state,
        sessionStatus: 'stopped',
      }
    case SESSION_PAUSED:
      const actualSession = {...state.session}
      actualSession.time = action.session_time
      return {
        ...state,
        sessionStatus: 'paused',
        session: {...actualSession}
      }
    case SESSION_STARTING:
      return {
        ...state,
        sessionStatus: 'starting',
      }
    case STORE_OPENED:
      return {
        ...state,
        isStoreOpen: true,
      }
    case STORE_CLOSED:
      return {
        ...state,
        isStoreOpen: false,
      }

    case TOKEN_CHECK:
    case WORKOUT_CREATED:
      return state

    case SESSION_LOADED:
    case SESSION_CREATED:
      return {
        ...state,
        sessionStatus: 'started',
        session: { ...state.session, ...action.payload },
      }
    case SESSION_UPDATED:
      return {
        ...state,
        session: { ...state.session, ...action.payload },
      }

    default:
      return state
  }
}
