import { ReactComponent as CartIcon } from 'assets/cart.svg'
import axios from 'axios'
import Spinner from 'components/Spinner'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { openStore } from 'state/action-creators'

interface SectionTitleBarProps {
  title: string
  state: any
  storeLink?: string
}

const SectionTitleBar = ({ state, title, storeLink }: SectionTitleBarProps) => {
  return (
    <div className="py-3 flex w-product justify-between items-center">
      <h1 className=" text-2xl font-bold">{title}</h1>
      {storeLink && <CartIcon
        fill="#ff6900"
        onClick={() => {
          //@ts-ignore
          window.open(storeLink, '_blank').focus()
          // openStore()
        }}
      />}
    </div>
  )
}
const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})
export default connect(mapStateToProps, { openStore })(SectionTitleBar)
