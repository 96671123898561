import './panel.css'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { openStore, checkToken, loadProgress } from 'state/action-creators'

//Components
import WeekCalendar from 'components/panel/WeekCalendar'
import Calendar from 'components/panel/Calendar'
import { ReactComponent as LightningIcon } from 'assets/lightning.svg'
import { ReactComponent as CartIcon } from 'assets/cart.svg'
import WorkoutBar from 'components/panel/WorkoutBar'
import ExercisesView from 'components/routine/ExercisesView'
import Button from 'components/layout/Button'
import Drawer from 'react-bottom-drawer'
import axios from 'axios'
import Spinner from 'components/Spinner'


interface PanelProps {
  state: any
  openStore: any
  checkToken: any
  loadProgress: () => void
}

const Panel = ({ openStore, checkToken, state, loadProgress }: PanelProps) => {
  const { userProgress, user, translations  } = state
  const ct = translations[user.language_id as '1']

  const [loading, setLoading] = useState(true)
  const [storeLink, setStoreLink] = useState<any>()

  const [section, setSection] = useState(0)
  const [routinePopup, setRoutinePopup] = useState(false)
  const toggleRoutinePopup = () => setRoutinePopup(!routinePopup)

  useEffect(() => {
    checkToken()
  }, []) //eslint-disable-line

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/language', {
      params: { language_id: user?.language_id },
    })
    const storeLang = res.data.find((value: any) => value.id === ( user.language_id as '1' ))
    setStoreLink(storeLang.store_link)
  }
  
  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading)
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  )

  return (
    <div className="ath-container pb-36">
      <div className="flex flex-col w-product mb-8 mt-4">
        <div className="flex justify-between">
          <div>
            <h3 className="panel-title">{ct?.panelMyPanel}</h3>
            <h1 className="panel-hello">{`${ct?.panelHello} ${userProgress?.name?.replace(/\s(.*)/, '')}`}</h1>
          </div>
          <CartIcon
            fill="#ff6900"
            onClick={() => {
              //@ts-ignore
              window.open(storeLink, '_blank').focus()
              // openStore()
            }}
          />
        </div>
        <div className="panel-objective">
          <LightningIcon style={{ height: '15px', margin: '0 12px' }} />
          <h3 className="panel-objective-title">{ct?.panelCurrentObjective}</h3>
          <h3 className="panel-objective-title2">{userProgress?.goal?.name}</h3>
        </div>
      </div>
      <div className="w-product">
        <div className="flex">
          <button
            className={`panel-section-title ${section === 0 ? 'panel-section-active' : ''}`}
            onClick={() => setSection(0)}
          >
            {ct?.panelProgress}
          </button>
          <button
            className={`panel-section-title ${section === 1 ? 'panel-section-active' : ''}`}
            onClick={() => setSection(1)}
          >
            {ct?.panelHistory}
          </button>
        </div>
      </div>
      <div className="panel-dashboard relative">
        <div className={section === 0 ? '' : 'hidden'}>
          <WorkoutBar />
          <div className="flex flex-col w-product my-10">
            <div className="flex justify-between items-center mb-2">
              <p className="panel-routine-title">{ct?.panelRoutine}</p>
              <Link className="panel-link-routine" to={'/home/routine'}>
                {ct?.panelSeeAllRoutines}
              </Link>
            </div>
            <Button onClick={toggleRoutinePopup} text={ct?.panelShowRoutine} className="green-button" />
          </div>
          <WeekCalendar week={userProgress.week} />
        </div>

        <div className={section === 1 ? '' : 'hidden'}>
          <Calendar />
        </div>
      </div>
      <Drawer
        isVisible={routinePopup}
        onClose={() => {
          toggleRoutinePopup()
        }}
      >
        <ExercisesView />
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { openStore, checkToken, loadProgress })(Panel)
