import './faq-element.css'
import { useState } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/down.svg'

interface FaqProps {
  question: string
  answer: string
}

const FaqElement = (props: FaqProps) => {
  const [show, setShow] = useState(false)
  return (
    <div className={`faq-element w-product ${show ? 'faq-active' : ''}`}>
      <button onClick={() => {setShow(!show)}} className="faq-question">
        {props.question}
        <div className="h-auto w-auto">
          <ArrowIcon
            className="faq-arrow"
            fill={show ? '#AAAAAA' : '#ff6900'}
          />
        </div>
      </button>
      <div className="faq-answer">{props.answer}</div>
    </div>
  )
}

export default FaqElement
