import './styles.css'
import { ReactComponent as LoadingLines } from 'assets/loadingLines.svg'
import { connect } from 'react-redux'

const Loading = (props: { state: any }) => {
  const { state } = props
  const langContent = state.translations
  const lang = (state.user.language_id as '1') || 1
  const ct = langContent[lang]

  return (
    <div className="loading-screen">
      <LoadingLines className="loading-decoration" />
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
      <div className="loading-text">{ct?.loading}</div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(Loading)
