import { createContext, ReactNode, useContext, useState } from 'react'
import './styles.css'

interface GifProviderProps {
  children: ReactNode
}

interface GifContextData {
  isOpen: boolean
  openGifModal: () => void
  closeGifModal: () => void
  setGifImage: React.Dispatch<React.SetStateAction<string>>
  gifImage: string
}

const GifContext = createContext<GifContextData>({} as GifContextData)

export const GifProvider = (props: GifProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [gifImage, setGifImage] = useState('')

  function openGifModal() {
    setIsOpen(true)
  }
  function closeGifModal() {
    setIsOpen(false)
  }

  return (
    <GifContext.Provider
      value={{
        isOpen,
        openGifModal,
        closeGifModal,
        gifImage,
        setGifImage,
      }}
    >
      {props.children}
    </GifContext.Provider>
  )
}

export function useGif() {
  const context = useContext(GifContext)
  return context
}

export function GifComponent() {
  const { closeGifModal, isOpen, gifImage } = useGif()
  return (
    <div onClick={closeGifModal} className={`${isOpen ? 'gif-modal-open' : 'gif-modal'}`}>
        <div className="gif-modal-content">
            <div className="close" onClick={closeGifModal}>+</div>
            <img src={gifImage} alt={gifImage} />
        </div>
    </div>
  )
}

export default GifContext
