//Hooks
import { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import { connect } from 'react-redux'

//Componentes
import Button from 'components/layout/Button'
import { SelectorButton } from 'components/layout/SelectorButton'
import DropdownInput from 'components/layout/DropdownInput'

import './formMedical.css'
interface FormMedicalProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  initialState?: {}
  state: any
}

const FormMedical = (props: FormMedicalProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const { next, getData, buttonText, initialState } = props

  const [regData, setRegData] = useState({
    is_cardiac: -1,
    arterial_pressure: -1,
    is_diabetic: -1,
    is_smoker: -1,
    had_surgery_last_12_months: -1,
    surgery: '',
    on_treatment_or_medication: -1,
    treatment_or_medication: '',
    has_posture_problem: -1,
    posture_problem: '',
    does_exercises: -1,
    exercise: '',
    exercise_frequency: 0,
    additional_information: '',
  })

  const {
    is_cardiac,
    arterial_pressure,
    is_diabetic,
    is_smoker,
    had_surgery_last_12_months,
    surgery,
    on_treatment_or_medication,
    treatment_or_medication,
    has_posture_problem,
    posture_problem,
    exercise,
    does_exercises,
    exercise_frequency,
    additional_information,
  } = regData

  useEffect(() => {
    if (initialState) setRegData({ ...regData, ...initialState })
    // eslint-disable-next-line
  }, [initialState])

  const check = (x: any) => {
    for (let key in x) {
      if (x[key] === -1) return false
    }
    return true
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({
      ...regData,
      [target.name]:
        target.value === '' ? '' : isNaN(Number(target.value)) ? String(target.value) : Number(target.value),
    })
  }

  const onChangeString = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({ ...regData, [target.name]: String(target.value) })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (check(regData)) {
      getData(regData)
      if (next) next()
    }
  }

  return (
    <form className="ath-container" onSubmit={onSubmit}>
      <div className="w-product grid-inputs">
        <div>
          <p className="form-med-label">{ct?.medicalDataHeartAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="is_cardiac"
            onChange={onChange}
            value={is_cardiac}
          />
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataBpAux}</p>
          <SelectorButton
            options={[
              [10, ct?.medicalDataBpLow],
              [20, ct?.medicalDataBpRegular],
              [30, ct?.medicalDataBpHigh],
            ]}
            name="arterial_pressure"
            onChange={onChange}
            value={arterial_pressure}
          />
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataDiabetesAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="is_diabetic"
            onChange={onChange}
            value={is_diabetic}
          />
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataSmokerAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="is_smoker"
            onChange={onChange}
            value={is_smoker}
          />
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataSurgeryAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="had_surgery_last_12_months"
            onChange={onChange}
            value={had_surgery_last_12_months}
          />
          <textarea
            className="med-textarea"
            placeholder={ct?.which}
            name="surgery"
            id=""
            cols={30}
            rows={1}
            style={{ display: had_surgery_last_12_months < 1 ? 'none' : 'block' }}
            disabled={had_surgery_last_12_months === 0 || had_surgery_last_12_months === -1}
            onChange={onChangeString}
            value={surgery}
          ></textarea>
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataTreatmentAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="on_treatment_or_medication"
            onChange={onChange}
            value={on_treatment_or_medication}
          />
          <textarea
            className="med-textarea"
            placeholder={ct?.which}
            name="treatment_or_medication"
            id=""
            cols={30}
            rows={1}
            style={{ display: on_treatment_or_medication < 1 ? 'none' : 'block' }}
            disabled={on_treatment_or_medication === 0 || on_treatment_or_medication === -1}
            onChange={onChangeString}
            value={treatment_or_medication}
          ></textarea>
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataPostureAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="has_posture_problem"
            onChange={onChange}
            value={has_posture_problem}
          />
          <textarea
            className="med-textarea"
            placeholder={ct?.which}
            name="posture_problem"
            id=""
            cols={30}
            rows={1}
            style={{ display: has_posture_problem < 1 ? 'none' : 'block' }}
            disabled={has_posture_problem === 0 || has_posture_problem === -1}
            onChange={onChangeString}
            value={posture_problem}
          ></textarea>
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataActivitiesAux}</p>
          <SelectorButton
            options={[
              [0, ct?.no],
              [1, ct?.yes],
            ]}
            name="does_exercises"
            onChange={onChange}
            value={does_exercises}
          />
          <textarea
            className="med-textarea"
            placeholder={ct?.which}
            name="exercise"
            id=""
            cols={30}
            rows={1}
            style={{ display: does_exercises < 1 ? 'none' : 'block' }}
            disabled={does_exercises === 0 || does_exercises === -1}
            onChange={onChangeString}
            value={exercise}
          />
          <DropdownInput
            onChange={onChange}
            name="exercise_frequency"
            style={{ display: does_exercises < 1 ? 'none' : 'block' }}
            disabled={does_exercises === 0 || does_exercises === -1}
            className={`${exercise_frequency === 0 || exercise_frequency === null ? 'text-gray-400' : ''}`}
            value={exercise_frequency}
            options={[
              [0, ct['medicalDataActivitiesFreq0']],
              [1, ct['medicalDataActivitiesFreq1']],
              [2, ct['medicalDataActivitiesFreq2']],
              [3, ct['medicalDataActivitiesFreq3']],
              [4, ct['medicalDataActivitiesFreq4']],
              [5, ct['medicalDataActivitiesFreq5']],
              [6, ct['medicalDataActivitiesFreq6']],
              [7, ct['medicalDataActivitiesFreq7']],
              [8, ct['medicalDataActivitiesFreq8']],
            ]}
          />
        </div>

        <div className="mt-3">
          <p className="form-med-label">{ct?.medicalDataAdditionalAux}</p>
          <textarea
            className=" w-full p-3 bg-gray-100 rounded-lg mt-3"
            name="additional_information"
            id=""
            cols={1}
            rows={15}
            onChange={onChangeString}
            value={additional_information}
          ></textarea>
        </div>
        <Button className="main-button mt-5" disabled={!check(regData)} text={buttonText} />
      </div>
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormMedical)
