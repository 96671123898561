import './trainer.css'
import { useEffect, useState, Fragment, MouseEvent } from 'react'

import { connect } from 'react-redux'
import {
  hideTrainer,
  startSession,
  pauseSession,
  stopSession,
  createSession,
  updateSession,
  loadProgress,
  checkSession,
} from 'state/action-creators'

import axios from 'axios'

import TrainerCounter from './TrainerCounter'
import TrainerBg from 'assets/trainer-bg.png'
import Exercises from 'components/routine/Exercises'
import FinishScreen from './FinishScreen'
import Button from 'components/layout/Button'
import Drawer from 'react-bottom-drawer'
import Spinner from 'components/Spinner'

import { ReactComponent as PlayIcon } from 'assets/play.svg'
import { ReactComponent as PauseIcon } from 'assets/pause.svg'
import { ReactComponent as BackIcon } from 'assets/go-back.svg'
import { ReactComponent as ChartIcon } from 'assets/chart.svg'
import { ReactComponent as BrandIcon } from 'assets/brand.svg'

interface TrainerProps {
  state: any
  hideTrainer: () => void
  startSession: () => Promise<void>
  pauseSession: () => Promise<void>
  stopSession: () => void
  createSession: () => void
  updateSession: () => void
  loadProgress: () => void
  checkSession: any
}

const Trainer = (props: TrainerProps) => {
  const state = props.state
  const { isTrainerVisible, sessionStatus, session, userProgress } = state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const { hideTrainer, startSession, pauseSession, stopSession, createSession, updateSession, loadProgress, checkSession } = props
  const [time, setTime] = useState(0)
  const [finishPopup, setfinishPopup] = useState(false)
  const [finishScreen, setfinishScreen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  useEffect(() => {
    let interval: any

    if (sessionStatus === 'started') {
      checkSession(session?.id);

      if (sessionStatus === 'started') {
        if (session?.time) setTime(session?.time)
        interval = setInterval(() => setTime((prevTime) => prevTime + 1), 1000)
      }

    } else if (sessionStatus === 'stopped') {
      clearInterval(interval)
    } else {
      clearInterval(interval)
    }
  
    return () => {
      clearInterval(interval)
    }
  }, [sessionStatus]) //eslint-disable-line

  const closeTrainer = () => {
    document.body.setAttribute('style', '')
    hideTrainer()
  }

  const toggleSession = async () => {
    setLoading(true);
    try {
      if( sessionStatus === 'started' ){
        await pauseSession()
      } else {
        await startSession()
      }
    } catch(e: unknown) {}
    setLoading(false);
  }

  const counterEnd = () => {
    createSession()
    window.scrollTo(0, 0);
    let elementScroll = document.getElementsByClassName('exercises-wrapper');
    elementScroll[0].scrollTo(0, 0);
    
  }

  const exerciseClick = async (e: MouseEvent, id: string) => {
    const targetExercise = session.session_exercises.filter((exercise: { id: string }) => exercise.id === id)[0]

    if (targetExercise.status === true) {
      await axios.patch(process.env.REACT_APP_BASE_URL + '/user-training-session-exercise/finish', null, {
        params: {
          userTrainingSessionExerciseId: id,
        },
      })
      await updateSession()
    } else {
      await axios.patch(process.env.REACT_APP_BASE_URL + '/user-training-session-exercise/reactivate', null, {
        params: {
          userTrainingSessionExerciseId: id,
        },
      })
      await updateSession()
    }
  }

  const areAllFinished = () => {
    let result = 0
    session.session_exercises.forEach((exercise: { status: boolean }) => {
      if (exercise.status === true) result = result + 1
    })
    return result === 0
  }

  const finishSession = () => {
    if (areAllFinished()) {
      pauseSession()
      stopSession()
      setfinishScreen(true)
    } else {
      pauseSession()
      setfinishPopup(true)
    }
  }

  const medTime = () => {
    let len = userProgress.week.sessions.length
    let total = userProgress.week.total_time
    len = len === 0 ? 1 : len

    if (len === 1) return time

    return parseFloat((total / len).toFixed())
  }

  return (
    <Fragment>
      {sessionStatus === 'starting' && <TrainerCounter counterEnd={counterEnd} />}

      <div className={`${isTrainerVisible ? 'overflow-hidden' : 'hidden'} mobile-fix`}>
        <div
          style={{ background: `url(${TrainerBg}) center center/cover` }}
          className="ath-container mobile-fix absolute top-0 left-0 w-screen h-screen z-40"
        >
          <div className="trainer-overlay" />
          <div className="trainer-overlay2" />

          <div className="trainer-bar w-product mb-5">
            <button>
              <BackIcon onClick={closeTrainer} fill="white" />
            </button>
            <BrandIcon className="z-10 h-5" fill="white" />
            <button>
              <ChartIcon onClick={closeTrainer} fill="white" />
            </button>
          </div>

          <div style={{ zIndex: 20 }} className="exercises-wrapper">
            <Exercises data={session.session_exercises} onClick={exerciseClick} />
          </div>

          <h1 className="trainer-timer">
            {Math.floor(time / 3600) === 0 ? '' : `${Math.floor(time / 3600)}:`}
            {Math.floor(time / 60) % 60}:{time % 60 < 10 ? 0 : ''}
            {time % 60}
          </h1>

          <div className="trainer-button w-product">
            <Button text={ct?.trainerFinish} className="green-button" disabled={false} onClick={finishSession} />
          </div>

          {loading ? (
              <div className="w-full flex justify-center items-center resume-loading">
                <Spinner/>
              </div>
            ) : (
              <button className="timer-button" onClick={toggleSession}>
                {sessionStatus === 'started' ? (
                  <Fragment>
                    <PauseIcon className="mr-4" fill="white" />
                    {ct?.trainerPause}
                  </Fragment>
                ) : (
                  <Fragment>
                    <PlayIcon className="mr-4" fill="white" />
                    {ct?.trainerStart}
                  </Fragment>
                )}
              </button>
            )}
        </div>
      </div>

      <Drawer
        className="trainer-drawer"
        isVisible={finishPopup}
        onClose={() => {
          startSession()
          setfinishPopup(false)
        }}
      >
        <div className="ath-container">
          <div className="w-product">
            <h1 className="my-5 main-title w-full pb-5">{ct?.TrainerFinishTitle}</h1>
            <h1 className="text-sm text-gray-500">{ct?.TrainerFinishAux1}</h1>
            <h1 className="text-black font-semibold text-sm mt-5 mb-16">{ct?.TrainerFinishAux2}</h1>
            <div className="grid-inputs">
              <Button
                onClick={() => {
                  stopSession()
                  setfinishPopup(false)
                  setfinishScreen(true)
                }}
                text={ct?.TrainerFinishYes}
              />

              <button
                onClick={() => {
                  startSession()
                  setfinishPopup(false)
                }}
                className=" text-productPrimary font-semibold w-full"
              >
                {ct?.TrainerFinishNo}
              </button>
            </div>
          </div>
        </div>
      </Drawer>

      {finishScreen && (
        <FinishScreen
          med={medTime()}
          time={time}
          onClick={(e) => {
            setfinishScreen(false)
            setTime(0)
            hideTrainer()
            loadProgress()
          }}
        />
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  hideTrainer,
  startSession,
  pauseSession,
  stopSession,
  createSession,
  updateSession,
  loadProgress,
  checkSession
})(Trainer)
