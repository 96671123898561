import axios from 'axios'
import setAuthToken from 'utils/setAuthToken'

import {
  USER_DATA_LOADED,
  LOADING_END,
  LOADING_START,
  CLEAR_STATE,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  LOGIN_SUCCESS,
  PROGRESS_LOADED,
  SHOW_TRAINER,
  HIDE_TRAINER,
  SESSION_STARTED,
  SESSION_STOPPED,
  SESSION_PAUSED,
  SESSION_STARTING,
  STORE_OPENED,
  STORE_CLOSED,
  WORKOUT_CREATED,
  EXERCISES_LOADED,
  SESSION_CREATED,
  SESSION_UPDATED,
  SESSION_LOADED,
  TOKEN_CHECK,
  LANG_CHANGED,
  COUNTRY_CHANGED,
  TRANSLATIONS_LOADED,
} from '../types'

// AUTH

export const loadUser = () => {
  return async (dispatch) => {
    if (localStorage.access_token) {
      setAuthToken(localStorage.access_token)

      try {
        const res = await axios.post(
          process.env.REACT_APP_BASE_URL + '/user/validate-token?token=' + localStorage.access_token
        )

        //workaround untill request above works as intended
        await axios.get(process.env.REACT_APP_BASE_URL + '/user-training')

        dispatch({ type: USER_LOADED, payload: res.data })
      } catch (error) {
        dispatch({ type: AUTH_ERROR })
      }
    } else {
      dispatch({ type: AUTH_ERROR })
    }
  }
}

export const checkToken = () => {
  return async (dispatch) => {
    if (localStorage.access_token) {
      setAuthToken(localStorage.access_token)

      try {
        await axios.get(process.env.REACT_APP_BASE_URL + '/user-training')
        dispatch({ type: TOKEN_CHECK })
      } catch (error) {
        dispatch({ type: AUTH_ERROR })
      }
    } else {
      dispatch({ type: AUTH_ERROR })
    }
  }
}

export const login = (loginData) => {
  return async (dispatch) => {
    const config = {
      'Content-Type': 'application/json',
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login`, loginData, config)
      dispatch({ type: LOGIN_SUCCESS, payload: res.data })
      loadUser()
    } catch (error) {
      dispatch({ type: LOGIN_FAIL, payload: error })
    }
  }
}

export const clearErrors = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ERRORS })
  }
}

export const changeLang = (lang) => {
  return (dispatch) => {
    dispatch({ type: LANG_CHANGED, payload: lang })
  }
}

export const changeCountry = (country) => {
  return (dispatch) => {
    dispatch({ type: COUNTRY_CHANGED, payload: country })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT })
  }
}

// LOADERS

export const loadUserData = () => {
  return async (dispatch, getState) => {
    const { user } = getState().general
    if (user) {
      const res = await axios.get(process.env.REACT_APP_BASE_URL + '/profile/' + user?.id)
      dispatch({ type: USER_DATA_LOADED, payload: res.data })
    }
  }
}

export const loadProgress = () => {
  return async (dispatch, getState) => {
    const { isAuth } = getState().general
    if (isAuth) {
      const res = await axios.get(process.env.REACT_APP_BASE_URL + '/user-training/progress')
      dispatch({ type: PROGRESS_LOADED, payload: res.data })
    }
  }
}

export const loadTranslations = () => {
  return async (dispatch) => {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/language/translations')
    dispatch({ type: TRANSLATIONS_LOADED, payload: res.data })
  }
}

// INTERNAL STATE

export const loadingEnd = () => {
  return (dispatch) => {
    document.body.setAttribute('style', '')
    dispatch({ type: LOADING_END })
  }
}

export const loadingStart = () => {
  return (dispatch) => {
    document.body.setAttribute('style', 'position: fixed')
    dispatch({ type: LOADING_START })
  }
}

export const clearState = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STATE })
  }
}

export const openStore = () => {
  return (dispatch) => {
    dispatch({ type: STORE_OPENED })
  }
}

export const closeStore = () => {
  return (dispatch) => {
    dispatch({ type: STORE_CLOSED })
  }
}

// TRAINER STATE

export const showTrainer = () => {
  return (dispatch) => {
    document.body.setAttribute('style', 'position: fixed; overflow: hidden')
    dispatch({ type: SHOW_TRAINER })
  }
}

export const hideTrainer = () => {
  return (dispatch) => {
    document.body.setAttribute('style', '')
    dispatch({ type: HIDE_TRAINER })
  }
}

export const startingSession = () => {
  return (dispatch) => {
    dispatch({ type: SESSION_STARTING })
  }
}

export const createSession = () => {
  return async (dispatch) => {
    let id

    await axios
      .post(process.env.REACT_APP_BASE_URL + '/user-training-session')
      .then((res) => (id = res.data.id))
      .catch((error) => {
        id = error.response.data.active_user_training_session_id
      })

    const res2 = await axios.get(process.env.REACT_APP_BASE_URL + `/user-training-session/${id}`)

    dispatch({ type: SESSION_CREATED, payload: res2.data })
  }
}

export const checkForSession = () => {
  return async (dispatch, getState) => {
    const { userProgress } = getState().general
    await axios
      .get(process.env.REACT_APP_BASE_URL + '/user-training/current-session', {
        params: { id: userProgress.active_training.id },
      })
      .then(async (res) => {
        const id = res.data.session.id
        if (!id) return
        const res2 = await axios.get(process.env.REACT_APP_BASE_URL + `/user-training-session/${id}`)
        dispatch({ type: SESSION_LOADED, payload: res2.data })
      })
      .catch(async (error) => {
        if (error) return null
      })
  }
}

export const updateSession = () => {
  return async (dispatch, getState) => {
    const { session } = getState().general
    const res2 = await axios.get(process.env.REACT_APP_BASE_URL + `/user-training-session/${session.id}`)

    dispatch({ type: SESSION_UPDATED, payload: res2.data })
  }
}

export const updateExercise = (data) => {
  return (dispatch) => {
    dispatch({ type: SESSION_UPDATED, payload: { exercises: data } })
  }
}

export const startSession = () => {
  return async (dispatch, getState) => {
    const { session } = getState().general
    await axios.patch(process.env.REACT_APP_BASE_URL + '/user-training-session/start', null, {
      params: {
        userTrainingSessionId: session.id,
      },
    }).then(async (res) => {
      dispatch({ type: SESSION_STARTED })
    })
    .catch(async (error) => {
      if( error.response.status == 400) {
        dispatch({ type: SESSION_STARTED })
      }
    })
  }
}

export const stopSession = () => {
  return async (dispatch, getState) => {
    const { session, sessionStatus } = getState().general

    if (sessionStatus !== 'starting') {
      await axios.patch(process.env.REACT_APP_BASE_URL + '/user-training-session/stop', null, {
        params: {
          userTrainingSessionId: session.id,
        },
      }).then(async (res) => {
        dispatch({ type: SESSION_STOPPED })
      })
      .catch(async (error) => {
        if( error.response.status == 400) {
          dispatch({ type: SESSION_STOPPED })
        }
      })
    }

    dispatch({ type: SESSION_STOPPED })
  }
}

export const pauseSession = () => {
  return async (dispatch, getState) => {
    const { session } = getState().general
    const res = await axios.patch(process.env.REACT_APP_BASE_URL + '/user-training-session/pause', null, {
      params: {
        userTrainingSessionId: session.id,
      },
    })
    dispatch({ type: SESSION_PAUSED, session_time: res.data.session.time })
  }
}

export const checkSession = (valor) => {
  return async (dispatch, getState) => {
    const { session } = getState().general
    const res = await axios.patch(process.env.REACT_APP_BASE_URL + '/user-training-session/check', null, {
      params: {
        userTrainingSessionId: valor,
      },
    })

    console.log(res);

    if( res.data.state === 'paused' ){
      dispatch({type: SESSION_PAUSED, session_time: res.data.time});
    }else{
      dispatch({type: SESSION_STARTED})
    }
  }
}

export const createWorkout = () => {
  return async (dispatch, getState) => {
    await axios.get(process.env.REACT_APP_BASE_URL + '/user-training/new')
    dispatch({ type: WORKOUT_CREATED })
    // loadProgress()
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/user-training/progress')
    dispatch({ type: PROGRESS_LOADED, payload: res.data })
    //loadExercises()
    const res2 = await axios.get(process.env.REACT_APP_BASE_URL + '/user-training/exercises')
    dispatch({ type: EXERCISES_LOADED, payload: res2.data })
  }
}
