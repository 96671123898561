import './content-filter-bar.css'
import DropdownInput from 'components/layout/DropdownInput'
import { ChangeEvent } from 'react'
import { useHistory } from 'react-router'

//REDUX
import { connect } from 'react-redux'


const ContentFilterBar = (props: {
  state: any
  contentCategories: any
  goals: any
}) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const history = useHistory()

  let categoriesOptions = []
  categoriesOptions.push([-1 as number, ct?.categories as string])

  props.contentCategories.forEach(
    (option: { id: string; title: string }, index: number) => {
      categoriesOptions.push([Number(option.id), option.title])
    }
  )

  let goalsOptions = []
  goalsOptions.push([-1 as number, ct?.goals as string])

  props.goals.forEach((option: { id: string; name: string }) => {
    goalsOptions.push([Number(option.id), option.name])
  })

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const selectedField = target.name
    const selectedValue = parseInt(target.value)

    if (selectedValue === -1) return
    if (selectedField === 'category')
      history.push('/home/content/category/' + selectedValue)
    if (selectedField === 'goal')
      history.push('/home/content/goal/' + selectedValue)
  }

  return (
    <div className="filter-bar-wrapper">
      <div className="w-product filter-bar">
        <DropdownInput
          className="dropdown-input ter base-value"
          name="category"
          onChange={onChange}
          options={categoriesOptions}
        />
        <DropdownInput
          className="dropdown-input ter base-value"
          name="goal"
          onChange={onChange}
          options={goalsOptions}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ContentFilterBar)
