//Hooks
import { ChangeEvent, FormEvent, useState } from 'react'
import { connect } from 'react-redux'

//Componentes
import { BigInputField, SmallInputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'
import formatarMedidas from 'utils/formatarMedidas'

interface FormMeasuresProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string

  state: any
}

const FormMeasures = (props: FormMeasuresProps) => {
  const { next, getData, buttonText } = props
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const [regData, setRegData] = useState({
    waist: '',
    waist_unit: 10,
    hip: '',
    hip_unit: 10,
  })

  const { waist, waist_unit, hip, hip_unit } = regData

  const check = (x: any) => {
    let check = parseFloat(x)
    return check > 1
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    if (target.name === 'waist_unit') {
      setRegData({
        ...regData,
        waist: '',
        [target.name]: Number(target.value),
      })
      return
    }
    if (target.name === 'hip_unit') {
      setRegData({
        ...regData,
        hip: '',
        [target.name]: Number(target.value),
      })
      return
    } else
      setRegData({
        ...regData,
        [target.name]: formatarMedidas(target.value),
      })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (check(waist) && check(hip)) {
      getData({
        waist: Number(waist),
        waist_unit: waist_unit,
        hip: Number(hip),
        hip_unit: hip_unit,
      })
      if (next) next()
    }
  }
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="grid-inputs">
        <h1>{ct?.regChooseWaistMeasures}</h1>
        <div className="relative">
          <BigInputField value={waist} name="waist" onChange={onChange} type="tel" maxLength={6} placeholder="0" />
          <SmallInputField
            onChange={onChange}
            value={waist_unit}
            name="waist_unit"
            type="text"
            options={[
              [10, 'cm'],
              [20, 'in'],
            ]}
          />
        </div>
        <h1 className="mt-2">{ct?.regChooseHipMeasures}</h1>
        <div className="relative">
          <BigInputField value={hip} name="hip" onChange={onChange} type="tel" maxLength={6} placeholder="0" />
          <SmallInputField
            onChange={onChange}
            value={hip_unit}
            name="hipUnit"
            type="text"
            options={[
              [10, 'cm'],
              [20, 'in'],
            ]}
          />
        </div>
      </div>
      <Button className="main-button mt-5" disabled={!(check(waist) && check(hip))} text={buttonText} />
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormMeasures)
