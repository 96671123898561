import { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'components/layout/telephoneInput.css'

interface InputFieldProps {
  label?: string
  type?: string
  value: string
  onChange: any
  className?: string
  isValid?: boolean
  invalidLabel?: string
  country: string
}

// EXPORT
const TelephoneInput = (props: InputFieldProps) => {
  const { label, value, onChange, invalidLabel, isValid, country } = props

  //Label animation
  const [isActive, setIsActive] = useState(false)
  const _onChange = (value: string): void => {
    setIsActive(value !== '' ? true : false)
    onChange(value)
  }

  useEffect(() => {
    if (value !== '') setIsActive(true)
  }, [value])

  return (
    <div id='input' className='relative'>
      <PhoneInput
        country={country}
        isValid={isValid}
        value={value}
        onChange={_onChange}
      />
      <label
        className={`tel-input-label ${isActive ? 'active' : ''} ${
          isValid ? '' : 'tel-invalid-label'
        }`}
      >
        {isValid ? label : invalidLabel}
      </label>
    </div>
  )
}

TelephoneInput.defaultProps = {
  type: 'text',
  isValid: true,
}

export default TelephoneInput
