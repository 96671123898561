import './new-routine.css'
import { connect } from 'react-redux'
import { loadingStart, loadingEnd } from 'state/action-creators'
import { useHistory } from 'react-router-dom'

import RegisterContext from 'context/register/registerContext'
import { useState, useContext, Fragment, useEffect } from 'react'

import FormMedical from 'components/forms/FormMedical'
import FormEquips from 'components/forms/FormEquips'
import FormObjective from 'components/forms/FormObjective'
import MenuBar from 'components/layout/MenuBar'

import Button from 'components/layout/Button'

interface NewRoutineProps {
  loadingStart: () => void
  loadingEnd: () => void
  state: any
}

const NewRoutine = (props: NewRoutineProps) => {
  const registerContext = useContext(RegisterContext)
  const { updateUser, updateEquips } = registerContext
  
  const state = props.state
  const langContent = state.translations 
  const history = useHistory()
  const ct = langContent[state.user.language_id as '1']
  const { loadingStart, loadingEnd } = props

  const [compState, setCompState] = useState({
    userData: {},
    equipsData: [],
  })
  const { userData, equipsData } = compState

  const [formStep, setFormStep] = useState(0)
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    if (finished) {
      finish()
    }

    return () => {
    }
  }, [finished]) //eslint-disable-line

  const finish = async () => {
    loadingStart()
    await updateEquips(compState.equipsData)
    await updateUser(compState.userData, true, true)
    loadingEnd()
    console.log('TERMINOU')
    history.push('/home/panel')
  }

  const getUserData = (data: {}) => {
    setCompState({ ...compState, userData: { ...userData, ...data } })
  }
  const getEquipsData = (data: {}) => {
    setCompState({ ...compState, equipsData: { ...equipsData, ...data } })
  }

  const nextStep = () => setFormStep(formStep + 1)
  const prevStep = () => setFormStep(formStep - 1)

  return (
    <Fragment>
      {formStep === 0 ? (
        <div className="ath-container">
          <div className="w-product my-8">
            <h1 className="new-routine-title">{ct?.routineNewPlan}</h1>
            <div className="new-routine-aux-wrapper">
              <span className="new-routine-aux1">{ct?.routineNewPlanAux1}</span>
              &nbsp;
              <span className="new-routine-aux2">{ct?.routineNewPlanAux2}</span>
              <br />
              <br />
              <span className="new-routine-aux3">{ct?.routineNewPlanAux3}</span>
            </div>
            <Button text={ct?.routineNewPlanStart} onClick={nextStep} />
          </div>
        </div>
      ) : formStep === 1 ? (
        <div className="ath-container">
          <div className="w-product">
            <MenuBar onClick={prevStep} />
            <h1 className="new-routine-title">{ct?.regChooseMedicalData}</h1>
            <FormMedical
              next={nextStep}
              buttonText={ct?.regSend}
              getData={getUserData}
            />
          </div>
        </div>
      ) : formStep === 2 ? (
        <div className="ath-container">
          <div className="w-product">
            <MenuBar onClick={prevStep} />
            <h1 className="new-routine-title">{ct?.regChooseMedicalData}</h1>
            <FormEquips
              next={nextStep}
              buttonText={ct?.regSend}
              getData={getEquipsData}
            />
          </div>
        </div>
      ) : formStep === 3 ? (
        <div className="ath-container">
          <div className="w-product">
            <MenuBar onClick={prevStep} />
            <h1 className="new-routine-title">{ct?.regChooseMedicalData}</h1>
            <FormObjective
              next={nextStep}
              buttonText={ct?.routineNewPlanFinish}
              getData={(data) => {
                getUserData(data)
                setFinished(true)
              }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { loadingStart, loadingEnd })(
  NewRoutine
)
