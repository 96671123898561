import './workout-bar.css'
import { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'

const WorkoutBar = (props: { state: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const userProgress = useMemo(() => state.userProgress, [state.userProgress])

  const [progressState, setProgressState] = useState({
    completed: 1,
    total: 10,
  })

  const { total, completed } = progressState

  useEffect(() => {
    setProgressState({
      ...progressState,
      total: userProgress.expected_sessions,
      completed: userProgress.finished_trainings_count,
    })
  }, []) //eslint-disable-line

  let _barSize = (completed / total) * 100
  let barSize = _barSize < 10 ? 15 : _barSize

  const workoutsLeft = total - completed

  if (userProgress.finished_all_trainings === false) {
    return (
      <div className="w-product">
        <div className="workoutbar-wrapper">
          <div className="workoutbar" style={{ width: `${barSize}%` }}>
            <div className="workout-elements-wrapper">
              <div className="workout-progress-box">{`${completed}/${total}`}</div>
            </div>
            <div className="workout-elements-wrapper">
              {_barSize > 0 && <div className="workout-progress-bar"></div>}
            </div>
          </div>
          <div className="workout-progress-bar-fill"></div>
        </div>
        {total !== completed ? (
          <div className="w-full">
            <span className="workout-progress-text1">
              {`${
                workoutsLeft === 1 ? ct?.panelWorkoutAux1alt : ct?.panelWorkoutAux1
              } 
              ${workoutsLeft} 
              ${
                workoutsLeft === 1 ? ct?.panelWorkoutAux2alt : ct?.panelWorkoutAux2
              } `}
            </span>
            <span className="workout-progress-text2">{ct?.panelWorkoutAux3}</span>
          </div>
        ) : (
          <div className="w-full flex justify-center workout-progress-text1 mt-1">
            {`${ct?.panelWorkoutAuxFinished}`}
          </div>
        )}
      </div>
    )
  } else {
    return (<div></div>);
  }
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(WorkoutBar)
