import React, { MouseEventHandler } from 'react'
import { ReactComponent as BrandIcon } from 'assets/brand.svg'
import { ReactComponent as GoBackIcon } from 'assets/go-back.svg'

const MenuBar = (props: { onClick: MouseEventHandler }) => {
  return (
    <div className="profile-bar-wrapper">
      <div className="profile-bar">
        <button className="absolute w-8" onClick={props.onClick}>
          <GoBackIcon fill="#666666" />
        </button>
        <BrandIcon style={{ height: '13px' }} fill="#FF6900" />
      </div>
    </div>
  )
}

export default MenuBar
