import './modal.css'
import Overlay from './Overlay'
import { ReactComponent as XIcon } from 'assets/closex.svg'
import { MouseEventHandler } from 'react'

const Modal = (props: { onClick: MouseEventHandler; title: string; content: string }) => {
  return (
    <Overlay>
      <div className="modal">
        <div className="modal-title">{props.title}</div>
        <div className="modal-content policies" dangerouslySetInnerHTML={{ __html: props.content }} />
        <XIcon onClick={props.onClick} className="modal-close" fill="#ff6900" />
      </div>
    </Overlay>
  )
}

export default Modal
