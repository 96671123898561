import { useState, ChangeEvent, FormEvent } from 'react'
import { connect } from 'react-redux'
import { InputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'
import MenuBar from 'components/layout/MenuBar'
import { useHistory } from 'react-router'
import { ReactComponent as CheckCircleIcon } from 'assets/checkcircle.svg'
import axios from 'axios'

const ForgotPassword = (props: { state: any }) => {
  const { state } = props
  const history = useHistory()
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const [email, setEmail] = useState('')
  const [emailisValid, setEmailValid] = useState(true)
  const [step, setStep] = useState(0)

  async function onSubmit(e: FormEvent) {
    e.preventDefault()
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const test = emailRegex.test(email)
    setEmailValid(test)
    if (test) {
      try {
        await axios.post(
          (process.env.REACT_APP_BASE_URL +
            '/user/password-recovery') as string,
          {
            email,
          }
        )
        setStep(1)
      } catch (error) {
        setEmailValid(false);
      }
    }
  }

  function onChange(e: ChangeEvent) {
    const target = e.target as HTMLInputElement
    setEmail(target.value)
  }

  const checkMatch = (): boolean => {
    // return test
    return email !== ''
  }

  return step === 0 ? (
    <div>
      <form className="ath-container" onSubmit={(e) => onSubmit(e)}>
        <MenuBar onClick={() => history.push('/login')} />
        <div className="w-product">
          <h1 className="main-title">{ct?.regForgotPass}</h1>
          <h1 className="sec-title mt-1">{ct?.regForgotPassAux}</h1>
          <div className="grid-inputs w-product">
            <InputField
              label={ct?.loginLabelEmail}
              value={email}
              name="email"
              onChange={(e) => onChange(e)}
              isValid={emailisValid}
              invalidLabel="Email inválido."
            />
          </div>
          <Button
            className="main-button"
            disabled={!checkMatch()}
            text={ct?.regForgotPassSend}
          />
        </div>
      </form>
    </div>
  ) : (
    <div className="ath-container">
      <MenuBar onClick={() => history.push('/')} />
      <div className="screen-full-height">
        <div className="flex flex-col h-full justify-start text-center pt-10">
          <div className="grid-inputs">
            <CheckCircleIcon className="w-full mb-10" />
            <div className="text-3xl font-bold text-center">
              {ct?.regForgotPassFinished}
            </div>
            <div className="sec-title">{ct?.regForgotPassFinishedAux}</div>
          </div>
        </div>
        <Button
          onClick={() => history.push('/')}
          className="main-button mb-10 flex-shrink-0"
          text={ct?.goBackStart}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ForgotPassword)
