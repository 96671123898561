import './routine-day.css'
import { connect } from 'react-redux'

const RoutineDay = (props: { order: number; date: string; id: number; onClick: Function; state: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const today = new Date().toISOString().substring(0, 10)
  let date = new Date(props.date)
  date.setDate(date.getDate() + 1)

  let isToday = today === props.date

  return (
    <div className="ath-container pt-5 ">
      <div className="pb-5 flex justify-between items-center w-product border-b border-gray-200">
        <div className="flex items-center">
          <div className={isToday ? 'routine-list-day today' : 'routine-list-day'}>{props.order}</div>
          <div className={isToday ? 'today routine-list-date' : 'routine-list-date'}>
            {`${ct[`routineWeekday${date.getDay()}`]}, ${date.getDate()} ${ct?.of} ${
              ct[`panelMonth${date.getMonth()}`]
            }`}
          </div>
        </div>
        <button
          onClick={(e) => props.onClick(date.getDay(), props.id)}
          className={isToday ? 'routine-list-button today' : 'routine-list-button'}
        >
          {ct?.routineSeeExercises}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(RoutineDay)
