import './exercise-element.css'
import { ReactComponent as CheckIcon } from 'assets/check2.svg'
import { ReactComponent as PlayGif } from 'assets/play-gif.svg'
import { connect } from 'react-redux'

import { useState } from 'react'
import { useGif } from 'context/gif'

interface ExerciseElementProps {
  id: string
  name: string
  series: string
  duration: string
  duration_type: string
  status: boolean
  hideCheckbox?: boolean
  onClick?: Function
  state: any
  image: string
}

const ExerciseElement = (props: ExerciseElementProps) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const { isOpen, setGifImage, openGifModal } = useGif()

  const { name, series, duration, duration_type, status, hideCheckbox, onClick, id, image } = props

  const [complete, setComplete] = useState(!status)

  return (
    <>
      <div className="flex w-product mt-4">
        {!hideCheckbox && (
          <div
            className="exercise-checkbox-wrapper mr-4"
            onClick={(e) => {
              setComplete(!complete)
              if (onClick) onClick(e, id)
            }}
          >
            <div className="exercise-checkbox" style={{ background: `${complete ? '#17D85C' : '#f2f2f2'}` }}>
              {complete ? <CheckIcon style={{ height: '10px' }} fill="white" /> : ''}
            </div>
          </div>
        )}
        <div className={hideCheckbox ? 'exercise-text-wrapper alt' : 'exercise-text-wrapper'}>
          <div className={`exercise-title ${complete && !hideCheckbox ? 'exercise-complete' : ''}`}>{name}</div>
          <div className="exercise-details">
            <span className="exercise-details-title">{ct?.exerciseSeries} </span>
            <span className="exercise-details-content">{series}x</span>
            <span className="ml-5 exercise-details-title">{ct?.exerciseDuration} </span>
            <span className="exercise-details-content">
              {duration} {duration_type}
            </span>
          </div>
          <button
            onClick={() => {
              setGifImage(image)
              openGifModal()
            }}
            className={`mt-2 botao-gif ${isOpen ? 'botao-gif-open' : ''}`}
          >
            <PlayGif />
            {ct?.gifButton}
          </button>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ExerciseElement)
