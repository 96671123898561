import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Spinner from 'components/Spinner'
import './profile-terms.css'

import ProfileMenuBar from 'components/profile/ProfileMenuBar'

const ProfileTerms = (props: { state: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const [loading, setLoading] = useState(true)
  const [terms, setTerms] = useState([{ text: '' }])

  async function termsLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/terms', {
      params: { language_id: state.user.language_id },
    })
    setTerms(res.data)
  }

  useEffect(() => {
    if (loading) termsLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  return loading ? (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  ) : (
    <div className="ath-container">
      <ProfileMenuBar text={ct?.profileMenuTerms} to="/home/profile" />
      <div className="w-product mt-10 policies" dangerouslySetInnerHTML={{ __html: terms[0].text }}></div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ProfileTerms)
