import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as RightChvIcon } from '../../assets/rightChevron.svg'
import './profileOption.css'

interface ProfileOptionProps {
  to: string
  text: string
  children: ReactElement
  className?: string
}

const ProfileOption = (props: ProfileOptionProps) => {
  const { to, text, children, className } = props

  const InsideElement = () => (
    <div className={className}>
      <div className="w-full flex items-center">
        {children}
        <span className="text-gray-500 flex-grow pl-3">{text}</span>
        <RightChvIcon className="h-3 w-3 mx-4" fill={className === 'profile-option sec' ? 'black' : '#FF6900'} />
      </div>
    </div>
  )
  if (to === '') {
    return <InsideElement />
  } else {
    return (
      <Link to={to}>
        <InsideElement />
      </Link>
    )
  }
}

ProfileOption.defaultProps = {
  className: 'profile-option',
}

export default ProfileOption
