import { Fragment, useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { loadingStart, loadingEnd, createWorkout } from 'state/action-creators'
import Drawer from 'react-bottom-drawer'

import RegisterContext from 'context/register/registerContext'

//Components
import ProfileMenuBar from 'components/profile/ProfileMenuBar'
import FormMedical from 'components/forms/FormMedical'
import Button from 'components/layout/Button'

interface ProfileMedicalProps {
  state: any
  loadingStart: () => void
  loadingEnd: () => void
  createWorkout: () => void
}

export const ProfileMedical = (props: ProfileMedicalProps) => {
  const history = useHistory()
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const { loadingStart, loadingEnd, createWorkout } = props
  const contextUserData = state.userData

  const registerContext = useContext(RegisterContext)
  const { updateUser } = registerContext

  const [showPopup, setShowPopup] = useState(false)
  const togglePopup = () => setShowPopup(!showPopup)

  const [medData, setMedData] = useState({
    is_cardiac: -1,
    arterial_pressure: -1,
    is_diabetic: -1,
    is_smoker: -1,
    had_surgery_last_12_months: -1,
    surgery: '',
    on_treatment_or_medication: -1,
    treatment_or_medication: '',
    has_posture_problem: -1,
    posture_problem: '',
    does_exercises: -1,
    exercise: '',
    exercise_frequency: -1,
    additional_information: '',
  })

  useEffect(() => {
    setMedData({
      ...medData,
      is_cardiac: contextUserData.is_cardiac,
      arterial_pressure: contextUserData.arterial_pressure?.id,
      is_diabetic: contextUserData.is_diabetic,
      is_smoker: contextUserData.is_smoker,
      had_surgery_last_12_months: contextUserData.had_surgery_last_12_months,
      surgery: contextUserData.surgery === null ? '' : contextUserData.surgery,
      on_treatment_or_medication:
        contextUserData.on_treatment_or_medication === null ? '' : contextUserData.on_treatment_or_medication,
      treatment_or_medication: contextUserData.treatment_or_medication,
      has_posture_problem: contextUserData.has_posture_problem === null ? '' : contextUserData.has_posture_problem,
      posture_problem: contextUserData.posture_problem,
      does_exercises: contextUserData.does_exercises === null ? '' : contextUserData.does_exercises,
      exercise: contextUserData.exercise,
      exercise_frequency: contextUserData.exercise_frequency,
      additional_information:
        contextUserData.additional_information === null ? '' : contextUserData.additional_information,
    })
    //eslint-disable-next-line
  }, [])

  const getData = (data: {}) => {
    togglePopup()
    setMedData({ ...medData, ...data })
  }

  const onSubmit = async () => {
    loadingStart()
    await updateUser(medData, true, true)
    await createWorkout()
    loadingEnd()
    history.push('/home/profile')
  }

  return (
    <div>
      <ProfileMenuBar to="/home/profile" text={ct?.profileMenuMedData} />
      <FormMedical getData={getData} buttonText={ct?.profileSave} initialState={medData} />
      <Drawer isVisible={showPopup} onClose={() => togglePopup()}>
        <Fragment>
          <div className="ath-container">
            <div className="w-product">
              <h1 className="my-5 main-title w-full">{ct?.profileMenuMedicalWarn}</h1>
              <h1 className="text-sm text-gray-500">{ct?.profileMenuMedicalWarnAux}</h1>
              <h1 className="text-black font-semibold text-sm mt-5 mb-16">{ct?.profileMenuObjectiveWarnAux2}</h1>
              <div className="grid-inputs">
                <Button text={ct?.profileMenuObjectiveWarnYes} onClick={onSubmit} />
                <Link to="/home/profile">
                  <button name="objNo" className=" text-productPrimary font-semibold w-full">
                    {ct?.profileMenuObjectiveWarnNo}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Fragment>
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  loadingStart,
  loadingEnd,
  createWorkout,
})(ProfileMedical)
