import './styles.css'
import { Fragment, MouseEvent, useState, useEffect, ChangeEvent } from 'react'

import { connect } from 'react-redux'
import {
  clearState,
  loadUser,
  loadingStart,
  loadingEnd,
  changeLang,
  changeCountry,
  loadTranslations,
} from 'state/action-creators'

//Components
import { useHistory } from 'react-router'
import Button from 'components/layout/Button'
import RegisterScreen from '../register/RegisterScreen'
import LoginScreen from './LoginScreen'
import DropdownInput from 'components/layout/DropdownInput'
import PWAModalIOS from 'components/PWAModalIOS'

//Assets
import { ReactComponent as BrandLogo } from '../../assets/brand.svg'
import splashBg from '../../assets/splash-bg.png'
import axios from 'axios'
import PWAModalAndroid from 'components/PWAModalAndroid'

interface LaunchProps {
  state: any
  clearState: any
  loadUser: any
  loadingStart: any
  loadingEnd: any
  changeLang: any
  changeCountry: any
  loadTranslations: any
}

const Launch = (props: LaunchProps) => {
  const { clearState, loadUser, loadingStart, loadingEnd, changeLang, changeCountry, loadTranslations } = props
  const { isAuth, error, user, loading, translations } = props.state
  const langContent = translations
  const ct = langContent[user.language_id as '1']
  const history = useHistory()

  const [loadingLocal, setLoadingLocal] = useState(true)
  const [langs, setLangs] = useState<any>()
  const [countries, setCountries] = useState<any>()

  const [loginOpen, setLoginOpen] = useState(false)
  const [registerOpen, setRegisterOpen] = useState(false)
  const [langChosen, setLangChosen] = useState(false)
  const [countryChosen, setCountryChosen] = useState(false)

  const toggleLogin = () => setLoginOpen(!loginOpen)
  const toggleRegister = () => setRegisterOpen(!registerOpen)

  const handleLoginClick = (e: MouseEvent) => {
    if ((e.target as HTMLButtonElement).name === 'close') toggleLogin()
    if ((e.target as HTMLButtonElement).name === 'register') {
      toggleLogin()
      toggleRegister()
    }
  }

  const handleRegisterClick = (e: MouseEvent) => {
    if ((e.target as HTMLButtonElement).name === 'close') toggleRegister()
    if ((e.target as HTMLButtonElement).name === 'login') {
      toggleLogin()
      toggleRegister()
    }
  }

  useEffect(() => {
    if (isAuth && !error && localStorage.access_token) history.push('/')
  }, [isAuth]) //eslint-disable-line

  // CLEAR OLD USER STATE AND RELOADS
  useEffect(() => {
    async function splashLoad() {
      loadingStart()

      const lCountry = sessionStorage.getItem('launch_country')
      const lLang = sessionStorage.getItem('launch_lang')

      if (lLang) {
        changeLang(lLang)
        setLangChosen(true)
      }
      if (lCountry) {
        changeCountry(lCountry)
        setCountryChosen(true)
      }

      if (isAuth === false) await clearState()
      await loadUser()
      await loadTranslations()

      const res = await axios.get(process.env.REACT_APP_BASE_URL + '/language', {
        params: { language_id: user?.language_id },
      })
      const res1 = await axios.get(process.env.REACT_APP_BASE_URL + '/country', {
        params: { language_id: user?.language_id },
      })
  
      setLangs(res.data)
      setCountries(res1.data)
      setLoadingLocal(false)
      loadingEnd()
    }

    splashLoad()
  }, []) //eslint-disable-line

  if (loadingLocal) return null

  return (
    <Fragment>
      <PWAModalIOS />
      <PWAModalAndroid />
      <div className="launch">
        <BrandLogo
          fill="white"
          className={`transition-all duration-500 ease-out w-full absolute h-10 z-10 ${
            loginOpen || registerOpen ? 'brand-active' : 'top-1/3'
          }`}
        />
        <img className="launch-img" src={splashBg} alt="Homem correndo" />
        <div className="launch-backdrop"></div>

        {!loading &&
          (langChosen && countryChosen ? (
            <div className="launch-content flex flex-col items-center justify-center mb-10">
              <div className="w-product grid-inputs flex-grow">
                <h1 className="launch-welcome ">{ct?.splashWelcome}</h1>
                <Button className="acc-button" text={ct?.createAccount} onClick={toggleRegister} />
                <Button className="-mt-1 sec-button" text={ct?.hasAccount} onClick={toggleLogin} />
              </div>
            </div>
          ) : (
            <div className="launch-content flex flex-col items-center justify-center mb-10">
              <div className="w-product grid-inputs flex-grow">
                <h1 className="launch-welcome ">{ct?.select}</h1>
                <DropdownInput
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== '0') {
                      sessionStorage.setItem('launch_lang', e.target.value)
                      changeLang(e.target.value)
                      setLangChosen(true)
                    } else setLangChosen(false)
                  }}
                  options={[['0', 'Idioma'], ...langs.map((item: any) => [item.id, item.name])]}
                ></DropdownInput>
                <DropdownInput
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== '0') {
                      sessionStorage.setItem('launch_country', e.target.value)
                      changeCountry(e.target.value)
                      setCountryChosen(true)
                    } else setCountryChosen(false)
                  }}
                  options={[['0', 'País'], ...countries.map((item: any) => [item.id, item.name])]}
                ></DropdownInput>
              </div>
            </div>
          ))}

        <div
          className={'popup-overlay2'}
          style={{ transform: `translateY(${loginOpen ? '0px' : '999px'})` }}
          onClick={(e) => {
            e.stopPropagation()
            toggleLogin()
          }}
        >
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span className="w-10 border-4 rounded-full mb-2" onClick={toggleLogin}></span>
            <LoginScreen onClick={handleLoginClick} />
          </div>
        </div>

        <div
          className={'popup-overlay2'}
          style={{ transform: `translateY(${registerOpen ? '0px' : '999px'})` }}
          onClick={(e) => {
            e.stopPropagation()
            toggleRegister()
          }}
        >
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span className="w-10 border-4 rounded-full mb-2" onClick={toggleRegister}></span>
            <RegisterScreen onClick={handleRegisterClick} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  clearState,
  loadUser,
  loadingStart,
  loadingEnd,
  changeLang,
  changeCountry,
  loadTranslations,
})(Launch)
