//Hooks
import { useState, ChangeEvent, FormEvent, useEffect } from 'react'
import { connect } from 'react-redux'

//Componentes
import Button from 'components/layout/Button'
import DropdownInput from 'components/layout/DropdownInput'
import CheckboxInput from '../layout/checkboxInput'
import axios from 'axios'
import Spinner from 'components/Spinner'

interface FormEquipsProps {
  next?: () => void
  getData: (data: { equipments: number[] }) => void
  equipamentsData?: any
  buttonText: string
  state: any
  initialState?: any
}

const FormEquips = ({ next, getData, buttonText, initialState, state }: FormEquipsProps) => {
  const ct = state.translations[state.user.language_id as '1']

  const [loading, setLoading] = useState(true)

  const [equips, setEquips] = useState<any>()

  const [regData, setRegData] = useState<any>({
    accessories: [],
    elyptics: -1,
    bycicles: -1,
    stations: -1,
    treadmills: -1,
    platforms: -1,
  })

  const { accessories, elyptics, bycicles, stations, treadmills, platforms } = regData

  const elypticsOptions = [
    [-1, ct?.regAthlEquipsNone],
  ]
  const byciclesOptions = [
    [-1, ct?.regAthlEquipsNone],
  ]
  const stationsOptions = [
    [-1, ct?.regAthlEquipsNone],
  ]
  const treadmillsOptions = [
    [-1, ct?.regAthlEquipsNone],
  ]
  const platformsOptions = [
    [-1, ct?.regAthlEquipsNone],
  ]

  const accessoriesOptions = [] as any

  if (equips) {
    equips.accessories.forEach((option: { id: number; name: string; label: string }) =>
      accessoriesOptions.push([option.id, option.label != null && option.label !== '' ? option.label : option.name])
    )
    equips.elyptics.forEach((option: { id: number; name: string; label: string }) =>
      elypticsOptions.push([option.id, option.label != null && option.label !== '' ? option.label : option.name])
    )
    equips.bycicles.forEach((option: { id: number; name: string; label: string }) =>
      byciclesOptions.push([option.id, option.label != null && option.label !== '' ? option.label : option.name])
    )
    equips.stations.forEach((option: { id: number; name: string; label: string }) =>
      stationsOptions.push([option.id, option.label != null && option.label !== '' ? option.label : option.name])
    )
    equips.treadmills.forEach((option: { id: number; name: string; label: string }) =>
      treadmillsOptions.push([option.id, option.label != null && option.label !== '' ? option.label : option.name])
    )
    equips.platforms.forEach((option: { id: number; name: string; label: string }) =>
      platformsOptions.push([option.id, option.label != null && option.label !== '' ? option.label : option.name])
    )
  }

  const check = () => {
    // for (const key in regData) {
    //   if (regData[key] === -1) return false
    // }
    return true
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({ ...regData, [target.name]: parseInt(target.value) })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const output: number[] = []

    for (const key in regData) {
      const objProp = key as 'elyptics' | 'bycicles' | 'stations' | 'treadmills' | 'platforms'

      if (regData[objProp] > 0) output.push(regData[objProp])
    }

    let checkeds = document.getElementsByClassName('checked')
    for (let i = 0; i < checkeds.length; i++) {
      let checked = (checkeds[i] as HTMLInputElement).value
      output.push(Number(checked))
    }

    if (check()) {
      getData({ equipments: output })
      if (next) next()
    }
  }

  useEffect(() => {
    console.log('initialState')
    console.log(initialState)
    if (initialState) setRegData({ ...regData, ...initialState })
    // eslint-disable-next-line
  }, [initialState])

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/equipment/by-category', {
      params: { language_id: state.user?.language_id },
    })
    setEquips(res.data)
  }

  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner/>
      </div>
    )

  return (
    <form onSubmit={onSubmit}>
      <div className="w-product grid-inputs">
        <div>
          <p className="mb-1">{ct?.athlEliptico}</p>
          <DropdownInput
            onChange={onChange}
            name="elyptics"
            className={`${elyptics === undefined ? 'text-gray-400' : ''}`}
            value={elyptics}
            options={elypticsOptions}
          />
        </div>
        <div>
          <p className="mb-1">{ct?.athlBicicleta}</p>
          <DropdownInput
            onChange={onChange}
            name="bycicles"
            className={`${bycicles === undefined ? 'text-gray-400' : ''}`}
            value={bycicles}
            options={byciclesOptions}
          />
        </div>
        <div>
          <p className="mb-1">{ct?.athlEstacao}</p>
          <DropdownInput
            onChange={onChange}
            name="stations"
            className={`${stations === undefined ? 'text-gray-400' : ''}`}
            value={stations}
            options={stationsOptions}
          />
        </div>
        <div>
          <p className="mb-1">{ct?.athlEsteira}</p>
          <DropdownInput
            onChange={onChange}
            name="treadmills"
            className={`${treadmills === undefined ? 'text-gray-400' : ''}`}
            value={treadmills}
            options={treadmillsOptions}
          />
        </div>
        <div>
          <p className="mb-1">{ct?.athlPlataforma}</p>
          <DropdownInput
            onChange={onChange}
            name="platforms"
            className={`${platforms === undefined ? 'text-gray-400' : ''}`}
            value={platforms}
            options={platformsOptions}
          />
        </div>
        <div>
          <p className="mb-1">{ct?.athlAcessorios}</p>
          <div className="dropdown-wrapper equipment-wrapper">
            {accessoriesOptions.map((option: any, key: any) => (
              <CheckboxInput name="accessories[]" value={accessories} option={option} key={key} />
            ))}
          </div>
        </div>

        <Button className={check() ? 'main-button mt-5' : 'disabled-button'} text={buttonText} disabled={!check} />
      </div>
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormEquips)
