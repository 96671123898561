interface StoreProps {}

const Store = (props: StoreProps) => {
  return (
    <div className="ath-container">
      <iframe
        width="95%"
        height="800px"
        title="storefront"
        src={process.env.REACT_APP_STORE_URL}
      ></iframe>
    </div>
  )
}

export default Store
