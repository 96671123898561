//Hooks
import { useState, FormEvent } from 'react'
import { connect } from 'react-redux'

//Components
import Button from 'components/layout/Button'
import { ReactComponent as MaleIcon } from 'assets/male.svg'
import { ReactComponent as FemaleIcon } from 'assets/female.svg'
import './formSex.css'


interface FormSexProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  state: any
}

const FormSex = (props: FormSexProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const { next, getData, buttonText } = props

  const [regData, setData] = useState({
    sex: -1,
  })

  const { sex } = regData

  const check = () => sex !== -1

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (check()) {
      getData(regData)
      if (next) next()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="grid-inputs">
        <div className="grid grid-cols-2 w-product">
          <button
            type="button"
            onClick={() => setData({ sex: 10 })}
            className={`gender-selector ${sex === 10 ? 'selected' : ''} `}
          >
            <MaleIcon className="gender-icon" fill="#ff9600" />
            <h1>{ct?.genderOptionMale}</h1>
          </button>
          <button
            type="button"
            onClick={() => setData({ sex: 20 })}
            className={`gender-selector justify-self-end ${
              sex === 20 ? 'selected' : ''
            }`}
          >
            <FemaleIcon className="gender-icon" fill="#ff9600" />
            <h1>{ct?.genderOptionFemale}</h1>
          </button>
        </div>
        <Button
          className="main-button mt-5"
          disabled={!check()}
          text={buttonText}
        />
      </div>
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormSex)
