export const COMPOSE_NEW_USER = 'COMPOSE_NEW_USER'
export const COMPOSE_USER_INFO = 'COMPOSE_USER_INFO'
export const COMPOSE_USER_EQUIPS = 'COMPOSE_USER_EQUIPS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const UPDATE_USER_EQUIPS_FAIL = 'UPDATE_USER_EQUIPS_FAIL'
export const UPDATE_USER_EQUIPS_SUCCESS = 'UPDATE_USER_EQUIPS_SUCCESS'
export const UPDATE_USER_EMAIL_FAIL = 'UPDATE_USER_EMAIL_FAIL'
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const USER_DATA_LOADED = 'USER_DATA_LOADED'
export const USER_ID_LANG_LOADED = 'USER_ID_LANG_LOADED'
export const CLEAR_STATE = 'CLEAR_STATE'
export const GOALS_LOADED = 'GOALS_LOADED'
export const LOADING_END = 'LOADING_END'
export const LOADING_START = 'LOADING_START'
export const EQUIPS_LOADED = 'EQUIPS_LOADED'
export const VIDEOS_LOADED = 'VIDEOS_LOADED'
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED'
export const PROGRESS_LOADED = 'PROGRESS_LOADED'
export const SHOW_TRAINER = 'SHOW_TRAINER'
export const HIDE_TRAINER = 'HIDE_TRAINER'
export const STORE_OPENED = 'STORE_OPENED'
export const STORE_CLOSED = 'STORE_CLOSED'
export const SESSION_STARTING = 'SESSION_STARTING'
export const SESSION_STARTED = 'SESSION_STARTED'
export const SESSION_STOPPED = 'SESSION_STOPPED'
export const SESSION_PAUSED = 'SESSION_PAUSED'
export const SESSION_CREATED = 'SESSION_CREATED'
export const SESSION_UPDATED = 'SESSION_UPDATED'
export const WORKOUT_CREATED = 'WORKOUT_CREATED'
