import {
  COMPOSE_NEW_USER,
  COMPOSE_USER_INFO,
  CLEAR_STATE,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  COMPOSE_USER_EQUIPS,
  UPDATE_USER_EQUIPS_SUCCESS,
  UPDATE_USER_EQUIPS_FAIL,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_EMAIL_FAIL,
} from '../types'

const initialState = {
  newUser: {},
  userInfo: {},
  userEquips: [],
  registerError: null,
  updateError: null,
  equipsError: null,
  emailError: null,
}

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return localStorage.setItem('access_token', action.payload.access_token)

    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_EQUIPS_SUCCESS:
    case UPDATE_USER_EMAIL_SUCCESS:
      return state

    case UPDATE_USER_EMAIL_FAIL:
      return {
        ...state,
        emailError: action.payload.response.data.message,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        updateError: action.payload.response.data.message,
      }

    case UPDATE_USER_EQUIPS_FAIL:
      return {
        ...state,
        equipsError: action.payload.response.data.message,
      }

    case REGISTER_FAIL:
      return {
        ...state,
        registerError: action.payload.response.data.message,
      }

    case COMPOSE_NEW_USER:
      return {
        ...state,
        newUser: { ...state.newUser, ...action.payload },
      }
    case COMPOSE_USER_INFO:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      }

    case COMPOSE_USER_EQUIPS:
      return {
        ...state,
        userEquips: action.payload,
      }

    case CLEAR_STATE:
      return initialState

    default:
      return state
  }
}
