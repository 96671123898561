import './profileMenu.css'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { logout, checkToken } from 'state/action-creators'

// Components
import SectionTitleBar from 'components/homescreen/SectionTitleBar'
import ProfileOption from '../../components/profile/ProfileOption'

//Assets
import { ReactComponent as ProfileIcon } from '../../assets/profile.svg'
import { ReactComponent as DocIcon } from '../../assets/doc.svg'
import { ReactComponent as BuildingIcon } from '../../assets/building.svg'
import { ReactComponent as LightningIcon } from '../../assets/lightning.svg'
import { ReactComponent as LockIcon } from '../../assets/lock.svg'
import { ReactComponent as HelpIcon } from '../../assets/help.svg'
import { ReactComponent as TermsIcon } from '../../assets/terms.svg'
import { ReactComponent as PrivacyIcon } from '../../assets/privacy.svg'
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg'
import { ReactComponent as BrandIcon } from '../../assets/brand.svg'
import bannerImg from 'assets/profile-banner-img.png'
import axios from 'axios'
import Spinner from 'components/Spinner'


interface ProfileMenuProps {
  state: any
  logout: () => void
  checkToken: () => void
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const [loading, setLoading] = useState(true)
  const [storeLink, setStoreLink] = useState<any>()
  const { logout } = props

  const onClickLogout = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if('caches' in window){
      caches.keys().then((names) => {
        names.forEach((name) => {
          console.log('Cache name ' + name);
          caches.delete(name);
        });
      });
      console.log('Cache Limpo com Sucesso');
    }

    logout()
  }

  async function contentLoad() {
    const res6 = await axios.get(process.env.REACT_APP_BASE_URL + '/language', {
      params: { language_id: state.user?.language_id },
    })

    const storeLang = res6.data.find((value: any) => value.id === ( state.user.language_id as '1' ))
    setStoreLink(storeLang.store_link)
  }

  useEffect(() => {
    props.checkToken()
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  useEffect(() => {
    props.checkToken()
  }, []) //eslint-disable-line

  if (loading)
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  )

  return (
    <div className="ath-container pb-28">
      <SectionTitleBar storeLink={storeLink} title={ct?.profileMenu} />
      <div className="profile-banner">
        <BrandIcon className="profile-banner-icon" fill="white" />
        <img src={bannerImg} alt="" />
      </div>
      <div className="grid-inputs mb-32">
        <ProfileOption to="/home/profile/user" text={ct?.profileMenuUserData}>
          <ProfileIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption to="/home/profile/med" text={ct?.profileMenuMedData}>
          <DocIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption
          to="/home/profile/objective"
          text={ct?.profileMenuObjective}
        >
          <LightningIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption
          to="/home/profile/equips"
          text={ct?.profileMenuAthlEquips}
        >
          <BuildingIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption
          to="/home/profile/password"
          text={ct?.profileMenuPassword}
        >
          <LockIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption to="/home/profile/faq" text={ct?.profileMenuFAQ}>
          <HelpIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption to="/home/profile/terms" text={ct?.profileMenuTerms}>
          <TermsIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>

        <ProfileOption to="/home/profile/policies" text={ct?.profileMenuPrivacy}>
          <PrivacyIcon className="h-5 w-5 mx-4" fill="#FF6900" />
        </ProfileOption>
        <div className="mt-3">
          <div onClick={(e) => onClickLogout(e)}>
            <ProfileOption
              to=""
              className="profile-option sec"
              text={ct?.profileMenuLogout}
            >
              <LogoutIcon className="h-5 w-5 mx-4" fill="black" />
            </ProfileOption>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any; checkToken: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { logout, checkToken })(ProfileMenu)
