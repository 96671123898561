import './routine.css'
import { connect } from 'react-redux'
import { checkToken } from 'state/action-creators'
import { useState, Fragment, useEffect } from 'react'

// import PopUp from 'components/PopUp'
import SectionTitleBar from 'components/homescreen/SectionTitleBar'
import WorkoutBar from 'components/panel/WorkoutBar'
import Button from 'components/layout/Button'
import NewRoutine from './NewRoutine'
import RoutineList from 'components/routine/RoutineList'
import ExercisesView from 'components/routine/ExercisesView'
import axios from 'axios'
import Spinner from 'components/Spinner'
import Drawer from 'react-bottom-drawer'

const Routine = (props: { state: any; checkToken: any; loadProgress: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const { userProgress } = state

  const [routine, setRoutine] = useState<any>(null)

  const [newPlan, setNewPlan] = useState(false)
  const togglenewPlan = () => setNewPlan(!newPlan)

  const [exercisesOpen, setExercisesOpen] = useState(false)
  const toggleExercises = () => setExercisesOpen(!exercisesOpen)

  const [exercisesDate, setExercisesDate] = useState(0)
  const [exercisesId, setExercisesId] = useState(0)

  const isTrainingComplete = () => {
    if (userProgress) return userProgress.expected_sessions === userProgress.finished_trainings_count
  }

  const [loading, setLoading] = useState(true)
  const [storeLink, setStoreLink] = useState<any>()

  async function contentLoad() {
    props.checkToken()

    axios
    .get(process.env.REACT_APP_BASE_URL + '/user-training/' + userProgress.active_training.id)
    .then((res) => setRoutine(res.data.sessions_total))

    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/language', {
      params: { language_id: state.user?.language_id },
    })

    const storeLang = res.data.find((value: any) => value.id === ( state.user.language_id as '1' ))
    setStoreLink(storeLang.store_link)
  }
  
  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  if (loading) return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  )

  return (
    <Fragment>
      <div className="ath-container">
        <SectionTitleBar title={ct?.routineTitle} storeLink={storeLink} />
        <div className="routine-progress-wrapper">
          <div className="w-product">
            <WorkoutBar />
            {isTrainingComplete() && (
              <Button text={ct?.routineNewPlan} type="button" onClick={togglenewPlan} className="blue-button" />
            )}
          </div>
        </div>
      </div>

      {routine ? (
        <RoutineList
          onClick={(data: number, id: number) => {
            setExercisesDate(data)
            setExercisesId(id)
            toggleExercises()
          }}
          sessions={routine}
        />
      ) : (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}

      <Drawer isVisible={newPlan} onClose={() => togglenewPlan()}>
        <NewRoutine />
      </Drawer>

      <Drawer isVisible={exercisesOpen} onClose={() => toggleExercises()}>
        <ExercisesView date={exercisesDate} id={exercisesId} />
      </Drawer>
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { checkToken })(Routine)
