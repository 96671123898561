import { useState, useContext, MouseEventHandler, FormEvent, ChangeEvent, Fragment } from 'react'
import RegisterContext from '../../context/register/registerContext'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

//Components
import { InputField } from '../../components/layout/InputField'
import Button from 'components/layout/Button'
import TelephoneInput from 'components/layout/TelephoneInput'

interface RegisterScreenProps {
  onClick: MouseEventHandler
  state: any
}

const RegisterScreen = (props: RegisterScreenProps) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const { onClick } = props

  //Hooks init
  const registerContext = useContext(RegisterContext)
  const history = useHistory()

  //State
  const [regData, setRegData] = useState({
    name: '',
    telephone: '',
    email: '',
    username: '',
  })
  let { name, telephone, email } = regData

  const onChange = (e: ChangeEvent | InputEvent): void => {
    const target = e.target as HTMLInputElement
    if (target.name === 'email') {
      setRegData({
        ...regData,
        [target.name]: target.value.trim().toLowerCase(),
        username: target.value.trim().toLowerCase(),
      })
    } else {
      setRegData({
        ...regData,
        [target.name]: target.value,
      })
    }
  }

  //Field checking state
  const [checkData, setCheckData] = useState<{
    emailIsValid: boolean
    nameIsValid: boolean
    phoneIsValid: boolean
    emailIsAvailable?: boolean
  }>({
    emailIsValid: true,
    nameIsValid: true,
    phoneIsValid: true,
    emailIsAvailable: true,
  })

  const { emailIsValid, nameIsValid, phoneIsValid, emailIsAvailable } = checkData

  const CheckEmailAvailability = async (email: string) => {
    const params = {
      email,
    }

    try {
      const res = await axios.get((process.env.REACT_APP_BASE_URL as string) + '/user/check-duplicate', { params })
      if (res.status === 204) return false
    } catch (error) {
      return true
    }
  }

  const check = async () => {
    // /[a-zA-Z]+\s{1}[a-zA-Z]+/
    const nameRegex = /[a-zA-Z\u00C0-\u00FF ]+/i
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const phoneRegex = /[0-9]{6}/

    const nameIsValidTest = nameRegex.test(name)
    const emailIsValidTest = emailRegex.test(email)
    const emailIsAvailableTest = await CheckEmailAvailability(email)
    const phoneIsValidTest = phoneRegex.test(telephone)
    const fieldsFullTest = name !== '' || telephone !== '' || email !== ''

    setCheckData({
      ...checkData,
      nameIsValid: nameIsValidTest,
      emailIsValid: emailIsValidTest,
      phoneIsValid: phoneIsValidTest,
      emailIsAvailable: emailIsAvailableTest,
    })

    return nameIsValidTest && emailIsValidTest && phoneIsValidTest && fieldsFullTest && emailIsAvailableTest
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (await check()) {
      registerContext.composeNewUser(regData)
      history.push('/register')
    }
  }

  return (
    <Fragment>
      <div className="ath-container mb-5">
        <div className="w-product">
          <form onSubmit={onSubmit} autoComplete="off">
            <h1 className="w-product main-title mt-5">{ct?.regTitle}</h1>
            <div className="grid-inputs">
              <InputField
                onChange={onChange}
                value={name}
                name="name"
                label={ct?.regLabelName}
                isValid={nameIsValid}
                invalidLabel={ct?.regLabelNameInvalid}
              />
              <InputField
                onChange={onChange}
                value={email}
                name="email"
                label={ct?.regLabelEmail}
                isValid={emailIsValid && emailIsAvailable}
                invalidLabel={!emailIsValid ? ct?.regLabelEmailInvalid : ct?.regLabelEmailUnavailable}
              />
              <TelephoneInput
                isValid={phoneIsValid}
                country={'br'}
                value={telephone}
                onChange={(telephone: any) => setRegData({ ...regData, telephone })}
                label={ct?.regLabelPhone}
                invalidLabel={ct?.regLabelPhoneInvalid}
              />
            </div>
            <Button text={ct?.regSend} />
          </form>
          <div className="mt-5 flex items-center w-full justify-center">
            <p className="text-gray-400">{ct?.regLoginText}</p>
            <button onClick={onClick} name="login" className="text-productPrimary font-semibold w-32">
              {ct?.regLogin}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(RegisterScreen)
