//Hooks
import { useState, ChangeEvent, FormEvent } from 'react'

//Componentes
import { BigInputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'
import {connect} from "react-redux";

interface FormAgeProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  state: any
}

const FormAge = (props: FormAgeProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const { next, getData, buttonText } = props

  const [regData, setRegData] = useState({
    age: '',
  })

  const { age } = regData

  const check = (x: any) => {
    let check = Number(x)
    return check > 0
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({
      ...regData,
      [target.name]: Number(target.value.replaceAll(/\D/gi, '')),
    })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (check(age)) {
      getData(regData)
      if (next) next()
    }
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="grid-inputs w-product">
        <BigInputField
          value={age}
          name="age"
          onChange={onChange}
          type="tel"
          maxLength={2}
          placeholder="30"
          label={ct?.formYearsOld}
        />
        <Button
          className="main-button mt-5"
          disabled={!check(age)}
          text={buttonText}
        />
      </div>
    </form>
  )
}

const mapStateToProps = (state: { general: any; checkToken: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormAge)
