import {useState, useEffect} from 'react'
import './checkboxInput.css'
import {ReactComponent as CheckIcon} from "../../assets/check2.svg";

interface CheckboxInputProps {
    value: any
    name?: string
    option: any
}

const CheckboxInput = (props: CheckboxInputProps) => {
    const { value, name, option } = props
    const [checked, setChecked] = useState(false)

    useEffect( () => {
        // console.log(value);
        // console.log(option);

        let checkeds = document.getElementsByClassName('checked')
        for (let i = 0; i < checkeds.length; i++) {
            let checked = (checkeds[i] as HTMLInputElement).value
            if( checked == option[0] )
                setChecked(true)
            // output.push(Number(checked))
        }
        // let condition = Array.isArray(value) && value.includes(parseInt(option[0]));
        // console.log(condition);
        // setChecked(condition);
    }, [option]);

    return (
        <>
            <div>
                <div className={`checkbox-equipment`} style={{background: `${checked ? '#ff6900' : '#f2f2f2'}` }} onClick={() => {
                    setChecked(!checked)
                }}>
                    {checked ? <CheckIcon style={{ height: '10px' }} fill="white" /> : ''}
                </div>
                <label htmlFor={name} onClick={() => {
                    setChecked(!checked)
                }}>{option[1]}</label>
                <input type={"hidden"} className={`equip-input ${checked ? 'checked' : ''}`} checked={checked ? true : false} name={name} value={option[0]} onChange={()=>{}} />
            </div>
        </>
    )
}

CheckboxInput.defaultProps = {
    type: 'text',
    iconFill: '#FF6900',
    className: 'dropdown-input',
}

export default CheckboxInput
