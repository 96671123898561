import { ChangeEvent, FormEvent, useState } from 'react'
import { connect } from 'react-redux'

import ProfileMenuBar from 'components/profile/ProfileMenuBar'
import { InputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'

import axios from 'axios'

const ProfilePassword = (props: { state: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const [regData, setRegData] = useState({
    old_password: '',
    newPass: '',
    newPassCheck: '',
  })

  const { newPass, newPassCheck, old_password } = regData
  const [passisValid, setPassValid] = useState(true)
  const [passwordIsMatch, setpasswordIsMatch] = useState(true)
  const [sentStatus, setsentStatus] = useState('')

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({ ...regData, [target.name]: target.value })
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
    const test = passwordRegex.test(newPass)
    setPassValid(test)
    setpasswordIsMatch(check(regData))
    if (test) {
      try {
        await axios.patch(
          (process.env.REACT_APP_BASE_URL as string) + '/user/update-password',
          {
            password: newPass,
            email: state.userData.email,
            password_old: old_password,
          }
        )
        setsentStatus('ok')
      } catch (error) {
        setsentStatus('error')
      }
    }
  }

  const check = (x: any) => {
    const test =
      x.newPass === x.newPassCheck && x.newPass !== '' && x.newPassCheck !== ''
    return test
  }

  return (
    <div className="ath-container">
      <ProfileMenuBar to="/home/profile" text={ct?.profileMenuPassword} />
      <form className="grid-inputs w-product" onSubmit={onSubmit}>
        {sentStatus === 'ok' && (
          <div className="faq-alert-ok">{ct?.profileMenuPasswordSuccess}</div>
        )}
        {sentStatus === 'error' && (
          <div className="faq-alert-error">{ct?.faqAlertError}</div>
        )}
        <InputField
          label={ct?.profilePassOldPass}
          value={old_password}
          name="old_password"
          type="password"
          onChange={onChange}
        />
        <InputField
          label={ct?.profilePassNewPass}
          value={newPass}
          name="newPass"
          type="password"
          onChange={onChange}
          isValid={passisValid}
          invalidLabel={ct?.regChoosePassInvalid}
        />
        <InputField
          label={ct?.profilePassNewPassCheck}
          value={newPassCheck}
          name="newPassCheck"
          type="password"
          onChange={onChange}
          isValid={passwordIsMatch}
          invalidLabel={ct?.regRepeatPassInvalid}
        />
        <p className="text-xs text-gray-400">{ct?.regChoosePassInvalidAux}</p>
        <div className="mt-5">
          <Button
            className={!check(regData) ? 'disabled-button' : 'main-button'}
            text={ct?.profileSave}
          />
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(ProfilePassword)
