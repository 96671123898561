import React from 'react'
import './articlePreview.css'
import { Link } from 'react-router-dom'

interface ArticlePreviewProps {
  header: string
  kicker: string
  image: string
  to: string
}

const ArticlePreview = (props: ArticlePreviewProps) => {
  const { header, kicker, image, to } = props
  return (
    <div className="ath-container">
      <div className="w-product">
        <Link to={to}>
          <div
            className="article-preview-img"
            style={{ background: `url(${image}) center center/cover` }}
          />
          <h1 className="article-preview-header">{header}</h1>
        </Link>
        <h1 className="article-preview-kicker">{kicker}</h1>
      </div>
    </div>
  )
}

export default ArticlePreview
