import './article.css'
import { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ArticleBar from 'components/content/ArticleBar'
import Button from 'components/layout/Button'
import RelatedArticles from 'components/content/RelatedArticles'
import { ReactComponent as ClockIcon } from 'assets/clock.svg'
import axios from 'axios'

const Article = (props) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id]
  const history = useHistory()
  const match = useRouteMatch()

  const articleId = match.params.id
  const [loading, setLoading] = useState(true)

  const [contentData, setContentData] = useState({
    id: -1,
    title: '',
    subtitle: '',
    content: '',
    reading_time: -1,
    goal: {
      id: -1,
      name: '',
    },
    category: {
      id: -1,
      title: '',
    },
    image: '',
  })

  async function contentLoad() {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL + '/content/' + articleId
    )
    setContentData(res.data)
    setLoading(false)
  }

  useEffect(() => {
    if (loading) contentLoad()
  }, [loading]) //eslint-disable-line

  return (
    !loading && (
      <div className="ath-container pb-40">
        <ArticleBar text={contentData.category.title} to="/home/content" />
        <div className="w-product pt-7">
          <div className="article-block">
            <div
              className="article-img"
              style={{
                background: `black url(${contentData.image}) center center/cover`,
              }}
            />
            <h1 className="article-header">{contentData.title}</h1>
            <div className="flex items-center">
              <span className="flex">
                <ClockIcon className="inline-block " fill="#ff6900" />
              </span>
              <span className="article-readingtime">{`${contentData.reading_time} min`}</span>
              <span className="article-readingtime-aux">{ct?.contentReading}</span>
            </div>
          </div>
          <div className="article-content">
            <div dangerouslySetInnerHTML={{ __html: contentData.content }}></div>
          </div>

          <RelatedArticles
            categoryId={contentData.category.id}
            articleId={contentData.id}
          />

          <Button
            onClick={() => {
              history.goBack()
            }}
            className="ter-button"
            text={ct?.goBackPage}
          />
        </div>
      </div>
    )
  )
}
const mapStateToProps = (state) => ({
  state: state.general,
})

export default connect(mapStateToProps)(Article)
