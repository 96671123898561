import { MouseEventHandler } from 'react'
import { connect } from 'react-redux'

import './finish-screen.css'
import { ReactComponent as LoadingLines } from 'assets/trainerCounterLines.svg'
import { ReactComponent as FinishCircle } from 'assets/finishCircle.svg'

interface FinishScreenProps {
  time: number
  med: number
  onClick: MouseEventHandler
  state: any
}

const FinishScreen = (props: FinishScreenProps) => {
  const { time, onClick, med } = props

  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  return (
    <div className="trainer-finish-screen">
      <LoadingLines className="absolute h-screen w-screen" />
      <div className="flex flex-col items-center justify-between h-full">
        <div className="flex flex-col items-center justify-center h-full">
          <FinishCircle
            style={{ backgroundColor: '#FF8220' }}
            className="p-2 z-10 rounded-full"
          />
          <p className="finish-screen-title">{ct?.TrainerCompleteTitle}</p>
          <p className="finish-screen-timer">
            {Math.floor(time / 3600) === 0 ? '' : `${Math.floor(time / 3600)}:`}
            {Math.floor(time / 60) % 60}:{time % 60 < 10 ? 0 : ''}
            {time % 60}
          </p>
          <p className="finish-screen-aux">
            {ct?.TrainerCompleteAux1}{' '}
            <strong className="text-inherit">{ct?.TrainerCompleteAux2}</strong>{' '}
            {ct?.TrainerCompleteAux3}{' '}
            <strong className="text-inherit">
              {Math.floor(med / 3600) === 0 ? '' : `${Math.floor(med / 3600)}:`}
              {Math.floor(med / 60) % 60}:{med % 60 < 10 ? 0 : ''}
              {med % 60}
            </strong>
          </p>
        </div>
        <button className="finish-screen-button" onClick={onClick}>
          {ct?.goToPanel}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FinishScreen)
