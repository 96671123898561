import './categoriesSelector.css'
import { Link } from 'react-router-dom'

//REDUX
import { connect } from 'react-redux'

interface contentCategories {
  id: string
  image: string
  title: string
}

const CategoriesSelector = (props: { state: any; contentCategories: any }) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  return (
    <div className="category-selector-wrapper ath-container">
      <h1 className="w-product font-bold text-lg pb-2">{ct?.categories}</h1>
      <div className="category-selector w-screen">
        {props.contentCategories.map(
          (e: contentCategories, index: number, array: []) => (
            <CategoryElement
              className={
                index === 0
                  ? 'category-first-item'
                  : index === array.length - 1
                  ? 'category-last-item'
                  : ''
              }
              key={e.id}
              to={'/home/content/category/' + e.id}
              image={e.image}
              title={e.title}
            />
          )
        )}
      </div>
    </div>
  )
}

interface CategoryElementProps {
  image: string
  title: string
  to: string
  className?: string
}

const CategoryElement = (props: CategoryElementProps) => {
  return (
    <Link
      className={`${props.className} category-element`}
      to={props.to}
      style={{ backgroundImage: `url(${props.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
    >
      <div className="category-overlay">
        <h1 className="category-title">{props.title}</h1>
      </div>
    </Link>
  )
}

CategoryElement.defaultProps = {
  className: '',
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(CategoriesSelector)
