//Hooks
import { useState, ChangeEvent, FormEvent } from 'react'
import { connect } from 'react-redux'

//Componentes
import { BigInputField, SmallInputField } from 'components/layout/InputField'
import Button from 'components/layout/Button'


interface FormHeightProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  state: any
}

const FormHeight = (props: FormHeightProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const { next, getData, buttonText } = props

  const [regData, setRegData] = useState({
    height: '',
    height_unit: 10,
  })

  const { height, height_unit } = regData

  const check = (x: string) => {
    let check = Number(x.replace('.', '').replace("'", ''))
    return !isNaN(check) && x !== '' && check > 99
  }

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    if (target.name === 'height_unit') {
      setRegData({
        ...regData,
        height: '',
        [target.name]: parseInt(target.value),
      })
      return
    }
    setRegData({
      ...regData,
      [target.name]: target.value
        .replace(
          height_unit === 10
            ? /\B(?=(\d{2})+(?!\d))/g
            : /\B(?=(\d{2})+(?!\d))/g,
          height_unit === 10 ? '.' : "'"
        )
        .replace(',', '.'),
    })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (check(height)) {
      getData({
        height_unit: height_unit,
        height: Number(height.replace('.', '').replace("'", '.')),
      })
      if (next) next()
    }
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="relative grid-inputs">
        <BigInputField
          value={height}
          name="height"
          onChange={onChange}
          type="tel"
          maxLength={4}
          placeholder="0"
        />
        <SmallInputField
          onChange={onChange}
          value={height_unit}
          name="height_unit"
          type="text"
          className="sm-input"
          options={[
            [10, ct?.heightUnitOptionM],
            [20, ct?.heightUnitOptionFt],
          ]}
        />
      </div>
      <Button
        className="main-button mt-5"
        disabled={!check(height)}
        text={buttonText}
      />
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormHeight)
