import './profileMenuBar.css'
import { Link } from 'react-router-dom'
import { ReactComponent as GoBackIcon } from 'assets/go-back.svg'

interface ProfileMenuBarProps {
  to: string
  text: string
}
const ProfileMenuBar = (props: ProfileMenuBarProps) => {
  const { to, text } = props
  return (
    <div className="profile-bar-wrapper">
      <div className="profile-bar">
        <Link className="absolute" to={to}>
          <GoBackIcon fill="#666666" />
        </Link>
        <h1 className="w-full text-center font-bold text-lg">{text}</h1>
      </div>
    </div>
  )
}

export default ProfileMenuBar
