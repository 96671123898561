import './week-calendar.css'
import TrackerDay from 'components/panel/TrackerDay'
import { Fragment, useState } from 'react'
import { ReactComponent as ClockIcon } from 'assets/clock.svg'
import { connect } from 'react-redux'

interface WeekCalendarProps {
  week: {
    sessions: {
      date: string
      duration: number
      finished_exercises: string
      finished_percentage: number
      id: number
      total_exercises: string
    }[]
    total_time: number
  }
  state: any
}

interface session {
  date: string
  duration: number
  finished_exercises: string
  finished_percentage: number
  id: number
  total_exercises: string
}

function WeekCalendar(props: WeekCalendarProps) {
  const { week, state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const today = new Date()

  let dayTrackers: JSX.Element[] = []

  const [calendarState] = useState({
    todayDate: today.getDate(),
    month: today.getMonth(),
    year: today.getFullYear(),
  })
  const { todayDate, month, year } = calendarState

  const daysUntilWeekend = (todayDay: number): number => {
    let weekday = new Date(year, month, todayDay).getDay()
    weekday = weekday === 0 ? 7 : weekday
    const finalWeekDay = 7 - weekday
    return finalWeekDay
  }

  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate()
  }

  const filterDays = (fullDate: string): session => {
    let dateDataArr = week?.sessions.filter((day) => day.date === fullDate)
    if (dateDataArr.length > 1) {
      let totalSeconds = dateDataArr.reduce((acc, cur) => {
        return acc + cur.duration
      }, 0)
      let totalPercentage = dateDataArr.reduce((acc, cur) => {
        return acc + cur.finished_percentage
      }, 0)
      let calculatePercentage = totalPercentage / dateDataArr.length
      return {
        date: '',
        duration: totalSeconds,
        finished_exercises: '',
        finished_percentage: calculatePercentage,
        id: -1,
        total_exercises: '',
      }
    } else {
      let dateData = week?.sessions.filter((day) => day.date === fullDate)[0]
      return dateData
    }
  }

  const lastDayofWeek = todayDate + daysUntilWeekend(todayDate)
  const daysInCurrentMonth = getDaysInMonth(month, year)
  const daysInLastMonth = getDaysInMonth(month - 1, year)

  function renderDayTrackers() {
    dayTrackers = []
    const todayFullDate = today.toISOString().substring(0, 10)

    for (let i = todayDate; i < lastDayofWeek + 1; i++) {
      let weekday = new Date(year, month, i).getDay()
      if (weekday === 0) weekday = 7
      let fullDate = new Date(year, month, i).toISOString().substring(0, 10)

      if (i > daysInCurrentMonth) {
        let n = i - daysInCurrentMonth
        let nweekday = new Date(year, month + 1, n).getDay()
        if (nweekday === 0) nweekday = 7
        let nfullDate = new Date(year, month + 1, n).toISOString().substring(0, 10)

        let dateData = week ? filterDays(nfullDate) : undefined

        dayTrackers.push(
          <TrackerDay
            key={nfullDate}
            barSize={dateData ? (Math.ceil(dateData.duration / 60) * 100) / 60 : 0}
            weekday={nweekday.toString() as '1' | '2' | '3' | '4' | '5' | '6' | '7'}
            monthDay={n.toString()}
            active={nfullDate === todayFullDate}
            minutes={dateData ? Math.ceil(dateData.duration / 60) : 0}
            big
          />
        )
      } else if (i === todayDate && weekday !== 1) {
        for (let j = todayDate - (weekday - 1); j < todayDate + 1; j++) {
          let n = j
          let nweekday = new Date(year, month, n).getDay()
          if (nweekday === 0) nweekday = 7
          let nfullDate = new Date(year, month, n).toISOString().substring(0, 10)

          if (j < 1) {
            n = daysInLastMonth + j
            nweekday = new Date(year, month - 1, n).getDay()
            if (nweekday === 0) nweekday = 7
            nfullDate = new Date(year, month - 1, n).toISOString().substring(0, 10)
          }

          let dateData = week ? filterDays(nfullDate) : undefined

          dayTrackers.push(
            <TrackerDay
              key={nfullDate}
              barSize={dateData ? (Math.ceil(dateData.duration / 60) * 100) / 60 : 0}
              weekday={nweekday.toString() as '1' | '2' | '3' | '4' | '5' | '6' | '7'}
              monthDay={n.toString()}
              active={nfullDate === todayFullDate}
              minutes={dateData ? Math.ceil(dateData.duration / 60) : 0}
              big
            />
          )
        }
      } else if (i <= daysInCurrentMonth) {
        let dateData = week ? filterDays(fullDate) : undefined

        dayTrackers.push(
          <TrackerDay
            key={fullDate}
            barSize={dateData ? (Math.ceil(dateData.duration / 60) * 100) / 60 : 0}
            weekday={weekday.toString() as '1' | '2' | '3' | '4' | '5' | '6' | '7'}
            monthDay={i.toString()}
            active={fullDate === todayFullDate}
            minutes={dateData ? Math.ceil(dateData.duration / 60) : 0}
            big
          />
        )
      }
    }
  }

  renderDayTrackers()

  return (
    <Fragment>
      <div className="week-calendar-wrapper w-product">
        <div className="week-calendar-titlebar">
          <p className="week-calendar-title">{ct?.panelWeekTitle}</p>
          <div className="flex items-center">
            <ClockIcon fill="#17D85C" style={{ height: '10px' }} />
            <p className="week-calendar-time">
              {Math.floor(week?.total_time / 3600) === 0 ? '' : `${Math.floor(week?.total_time / 3600)}h:`}
              {Math.floor(week?.total_time / 60) % 60 === 0 ? '' : `${Math.floor(week?.total_time / 60) % 60}m:`}
              {week?.total_time % 60 < 10 ? 0 : ''}
              {week?.total_time % 60}s
            </p>
          </div>
        </div>
        <div className="week-calendar">{dayTrackers.splice(0, 7)}</div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(WeekCalendar)
