import './faq.css'
import { ChangeEvent, FormEvent, useState, useEffect } from 'react'
import ProfileMenuBar from 'components/profile/ProfileMenuBar'
import FaqElement from 'components/profile/FaqElement'
import { InputField } from 'components/layout/InputField'
import TelephoneInput from 'components/layout/TelephoneInput'

import { connect } from 'react-redux'
import Button from 'components/layout/Button'
import axios from 'axios'
import Spinner from 'components/Spinner'

const Faq = (props: { state: any }) => {
  const { state } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']
  const [loading, setLoading] = useState(true)
  const [faqs, setFaqs] = useState([])

  async function faqsLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/faq', {
      params: { language_id: state.user.language_id },
    });
    setFaqs(res.data)
  }

  useEffect(() => {
    if (loading) faqsLoad().then(() => setLoading(false))
  }, [loading])

  const [emailForm, setEmailForm] = useState({
    name: '',
    from: '',
    telephone: '',
    text: '',
    sentStatus: '',
  })

  const { name, from, text, telephone, sentStatus } = emailForm

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const name = target.name
    const value = target.value

    setEmailForm({ ...emailForm, [name]: value })
  }

  const [checkData, setCheckData] = useState<{
    emailIsValid: boolean
    nameIsValid: boolean
    phoneIsValid: boolean
  }>({
    emailIsValid: true,
    nameIsValid: true,
    phoneIsValid: true,
  })

  const check = () => {
    const nameRegex = /[a-zA-Z]+\s{1}[a-zA-Z]+/
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const phoneRegex = /[0-9]{6}/

    const nameIsValidTest = nameRegex.test(name)
    const emailIsValidTest = emailRegex.test(from)
    const phoneIsValidTest = phoneRegex.test(telephone)
    const fieldsFullTest = name !== '' || telephone !== '' || from !== ''

    setCheckData({
      ...checkData,
      nameIsValid: nameIsValidTest,
      emailIsValid: emailIsValidTest,
      phoneIsValid: phoneIsValidTest,
    })

    return nameIsValidTest && emailIsValidTest && phoneIsValidTest && fieldsFullTest
  }

  const { emailIsValid, nameIsValid, phoneIsValid } = checkData

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (check()) {
      try {
        await axios.post((process.env.REACT_APP_BASE_URL as string) + '/faq/send-email', {
          name,
          from,
          text,
        })
        setEmailForm({ ...emailForm, sentStatus: 'ok' })
      } catch (error) {
        setEmailForm({ ...emailForm, sentStatus: 'error' })
      }
    }
  }

  return loading ? (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner></Spinner>
    </div>
  ) : (
    <div className="ath-container">
      <ProfileMenuBar text={ct?.faqTitle} to="/home/profile" />
      <div className="w-product">
        {ct.faqDescription1 && ct.faqDescription2 && ct.faqDescription3 && (
          <div className="faq-aux my-10">
            {ct?.faqDescription1}
            <br />
            <br />
            {ct?.faqDescription2}<br />
            <br />
            {ct?.faqDescription3}
          </div>
        )}
        <div className="flex flex-col">
          {faqs.map((faq: { id: string; question: string; answer: string }) => (
            <FaqElement key={faq.id} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        <div className="mt-10 mb-6">
          <div className="main-title">{ct?.faqEmailTitle}</div>
          <div className="faq-email-aux">{ct?.faqEmailAux}</div>
          <form onSubmit={onSubmit}>
            <div className="grid-inputs">
              {sentStatus === 'ok' && <div className="faq-alert-ok">{ct?.faqAlertOk}</div>}
              {sentStatus === 'error' && <div className="faq-alert-error">{ct?.faqAlertError}</div>}
              <InputField
                label={ct?.regLabelName}
                onChange={onChange}
                name="name"
                value={name}
                isValid={nameIsValid}
                invalidLabel={ct?.regLabelNameInvalid}
              />
              <InputField
                label={ct?.regLabelEmail}
                onChange={onChange}
                name="from"
                value={from}
                isValid={emailIsValid}
                invalidLabel={ct?.regLabelEmailInvalid}
              />
              <TelephoneInput
                label={ct?.regLabelPhone}
                onChange={(telephone: any) => setEmailForm({ ...emailForm, telephone })}
                value={''}
                country={'br'}
                isValid={phoneIsValid}
                invalidLabel={ct?.regLabelPhoneInvalid}
              />
              <textarea
                name="text"
                value={text}
                rows={6}
                placeholder={ct?.faqEmailField}
                className="text-area"
                onChange={onChange}
              ></textarea>
            </div>
            <Button text={ct?.faqEmailSend} />
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(Faq)
