//Hooks
import { useState, ChangeEvent, FormEvent } from 'react'
import { connect } from 'react-redux'

//Components
import Button from 'components/layout/Button'
import { InputField } from 'components/layout/InputField'


import { ReactComponent as CheckIcon } from 'assets/check.svg'

interface FormPassProps {
  next?: () => void
  getData: (data: {}) => void
  buttonText: string
  state: any
}

interface checkDataProps {
  passwordIsMatch?: boolean
  passwordIsValid?: boolean
}

const FormPassword = (props: FormPassProps) => {
  const state = props.state
  const langContent = state.translations
  const ct = langContent[state.user.language_id as '1']

  const { next, getData, buttonText } = props

  // State
  const [regData, setRegData] = useState({
    password: '',
    passwordCheck: '',
  })

  const [passCheck, setPassCheck] = useState({
    upper: false,
    lower: false,
    number: false,
    chars: false,
  })

  const { upper, lower, number, chars } = passCheck
  const { password, passwordCheck } = regData

  const [checkData, setCheckData] = useState<checkDataProps>({
    passwordIsMatch: true,
    passwordIsValid: true,
  })
  const { passwordIsMatch, passwordIsValid } = checkData

  const check = (): boolean => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

    const passwordIsValidTest = passwordRegex.test(password)
    const passwordIsMatchTest = password === passwordCheck
    const fieldsFullTest = password !== '' && passwordCheck !== ''

    setCheckData({
      ...checkData,
      passwordIsValid: passwordIsValidTest,
      passwordIsMatch: passwordIsMatchTest,
    })

    const checkOutput =
      passwordIsValidTest && passwordIsMatchTest && fieldsFullTest
    return checkOutput
  }

  //Methods
  const onChangeMain = (e: ChangeEvent) => {
    const upperRegExp = /(?=.*?[A-Z])/
    const lowerRegExp = /(?=.*?[a-z])/
    const charsRegExp = /.{8,}/
    const numberRegExp = /(?=.*?[0-9])/
    const target = e.target as HTMLInputElement
    setRegData({ ...regData, password: target.value })

    setPassCheck({
      upper: upperRegExp.test(target.value),
      lower: lowerRegExp.test(target.value),
      chars: charsRegExp.test(target.value),
      number: numberRegExp.test(target.value),
    })
  }

  const onChangeCheck = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setRegData({ ...regData, passwordCheck: target.value })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (check()) {
      getData({ password })
      if (next) next()
    }
  }

  //Method for enbaling/disabling button
  const checkMatch = (): boolean => {
    const isMatch =
      password === passwordCheck && password !== '' && passwordCheck !== ''
    return isMatch
  }

  return (
    <form className="ath-container" onSubmit={onSubmit}>
      <div className="grid-inputs w-product">
        <InputField
          label={ct?.loginLabelPass}
          value={password}
          name="password"
          type="password"
          onChange={onChangeMain}
          isValid={passwordIsValid}
          invalidLabel={ct?.regChoosePassInvalid}
        />
        <InputField
          label={ct?.regRepeatPass}
          value={passwordCheck}
          name="passwordCheck"
          type="password"
          onChange={onChangeCheck}
          isValid={passwordIsMatch}
          invalidLabel={ct?.regRepeatPassInvalid}
        />
        <p className="text-sm text-gray-400">{ct?.passVerTitle}</p>
        <ul>
          <li
            className={`text-xs ${
              !number ? 'text-gray-400' : 'pass-green'
            }`}
          >
            {number && <CheckIcon className="inline h-3" fill="green" />}
            {ct?.passVer1}
          </li>
          <li
            className={`text-xs ${!upper ? 'text-gray-400' : 'pass-green'}`}
          >
            {upper && <CheckIcon className="inline h-3" fill="green" />}
            {ct?.passVer2}
          </li>
          <li
            className={`text-xs ${!lower ? 'text-gray-400' : 'pass-green'}`}
          >
            {lower && <CheckIcon className="inline h-3" fill="green" />}
            {ct?.passVer3}
          </li>

          <li
            className={`text-xs ${!chars ? 'text-gray-400' : 'pass-green'}`}
          >
            {chars && <CheckIcon className="inline h-3" fill="green" />}
            {ct?.passVer4}
          </li>
        </ul>
        <Button
          className="main-button mt-5"
          disabled={!checkMatch()}
          text={buttonText}
        />
      </div>
    </form>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(FormPassword)
