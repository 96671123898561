import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import RegisterState from './context/register/registerState'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { Provider } from 'react-redux'
import { store } from './state/store'

import { GifProvider } from 'context/gif'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RegisterState>
        <GifProvider>
          <App />
        </GifProvider>
      </RegisterState>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
