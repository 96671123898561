import { MouseEventHandler } from 'react'
import './button.css'

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  text: string
  className?: string
  marginTopClass?: string
  onClick?: MouseEventHandler
  disabled: boolean
}

const Button = (props: ButtonProps) => {
  const { type, text, className, onClick, disabled, marginTopClass } = props

  return (
    <button
      type={type}
      className={disabled ? 'disabled-button mt-5' : `${className} button ${marginTopClass}`}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

Button.defaultProps = {
  disabled: false,
  className: 'main-button',
  marginTopClass: 'mt-5',
  type: 'submit',
}

export default Button
