import './videoPreview.css'
import 'react-modal-video/css/modal-video.css'

import { ReactComponent as PlayIcon } from 'assets/content/play.svg'
import { useState } from 'react'
import ModalVideo from 'react-modal-video'

interface VideoPreviewProps {
  header?: string
  url: string
}

function getId(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11) ? match[2] : '';
}

const VideoPlayer = (props: VideoPreviewProps) => {
  const { header, url } = props
  const id = getId(url)
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="ath-container">
      <div className="w-product rounded-lg">
        <div>
          <button onClick={()=> setOpen(true)} className="video-preview-img">
            <img src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`} alt="" className="img" />
            <PlayIcon className="video-play-icon" />
          </button>
          <ModalVideo channel='youtube' isOpen={isOpen} videoId={id} onClose={() => setOpen(false)} />
          <h1 className="video-header pt-3">{header}</h1>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer
