import { ChangeEventHandler } from 'react'
import { ReactComponent as DownIcon } from 'assets/down.svg'
import './dropdownInput.css'

interface DropdownInputProps {
  name?: string
  value?: string | number
  onChange: ChangeEventHandler
  options: (string | number)[][]
  className?: string
  iconFill?: string
  disabled?: boolean
  style?: any
}

const DropdownInput = (props: DropdownInputProps) => {
  const { onChange, value, name, options, className, iconFill, disabled, style } = props

  return (
      <div className="dropdown-wrapper">
        <select style={style} disabled={disabled} value={value} onChange={onChange} name={name} className={`dropdown-input ${className}`}>
          {options.map((option, index) => {
            // options format : array: [[val_opt1, text_opt1], [val_opt2, text_opt2], ...]
            return (
                <option key={option[0]} value={option[0]} >
                  {option[1]}
                </option>
            )
          })}
        </select>
        <DownIcon style={style} className="dropdown-input-icon" fill={iconFill} />
      </div>
  )
}

DropdownInput.defaultProps = {
  type: 'text',
  iconFill: '#FF6900',
  className: 'dropdown-input',
}

export default DropdownInput
