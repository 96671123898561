import RoutineDay from './RoutineDay'

interface session {
  date: string
  duration: number
  finished_exercises: string
  finished_percentage: number
  id: number
  total_exercises: string
}

const RoutineList = (props: { sessions: session[]; onClick: Function }) => {
  let days: string[] = []
  let ids: number[] = []

  function getDays() {
    const days = props.sessions.map((session) => session.date)
    return days
  }

  function getIds() {
    const ids = props.sessions.map((session) => session.id)
    return ids
  }

  if (props.sessions){
    days = getDays()
  }

  if (props.sessions){
    ids = getIds()
  }

  return (
    <div className="rotine-container mt-8 flex flex-col">
      {props.sessions.map((day, index) => (
        <RoutineDay onClick={props.onClick} order={index + 1} date={days[index]} id={ids[index]} />
      ))}
    </div>
  )
}

export default RoutineList
