import './radioButton.css'
import React, { ChangeEventHandler, Fragment } from 'react'

import { ReactComponent as RadioOffIcon } from '../../assets/radio_unchecked.svg'
import { ReactComponent as RadioOnIcon } from '../../assets/radio_checked.svg'

interface RadioButtonProps {
  name: string
  onChange: ChangeEventHandler
  options: [number, string][]
  value?: number
}

export const RadioButton = (props: RadioButtonProps) => {
  const { options, name, onChange, value } = props
  return (
    <Fragment>
      {options.map((option) => {
        return (
          <label key={option[0]} className="custom-radio">
            <input
              type="radio"
              id={option[0].toString()}
              name={name}
              value={option[0]}
              onChange={onChange}
              checked={value === option[0]}
            />
            <RadioOnIcon className="checkmark-on" />
            <RadioOffIcon className="checkmark-off" />
            <span>{option[1]}</span>
          </label>
        )
      })}
    </Fragment>
  )
}
