import { useEffect, useState } from 'react'
import './trainer-counter.css'
import { ReactComponent as LoadingLines } from 'assets/trainerCounterLines.svg'

import { connect } from 'react-redux'
import { hideTrainer, stopSession } from 'state/action-creators'


interface TrainerCounterProps {
  counterEnd: Function
  state: any
  hideTrainer: () => void
  stopSession: () => void
}

const TrainerCounter = (props: TrainerCounterProps) => {
  const state = props.state
  const { hideTrainer, stopSession } = props
  const langContent = state.translations
  const ct = langContent[state.user.language_id]

  const [timerState, setTimerState] = useState(3)

  useEffect(() => {
    let interval: any

    if (timerState > 1) {
      interval = setInterval(
        () => setTimerState((prevTime) => prevTime - 1),
        1000
      )
    } else {
      clearInterval(interval)
      props.counterEnd()
    }

    return () => {
      clearInterval(interval)
    }
  }, [timerState]) //eslint-disable-line

  const haltTrainer = () => {
    stopSession()
    hideTrainer()
  }

  return (
    <div className="trainer-counter">
      <div className="flex flex-col items-center justify-center">
        <h1 className="trainer-counter-number">{timerState}</h1>
        <h1 className="trainer-counter-text">
          {ct[`trainerCounter${timerState as 1 | 2 | 3}`] || ''}
        </h1>
      </div>
      <button
        style={{ zIndex: 50 }}
        className="trainer-halt-button"
        onClick={haltTrainer}
      >
        {ct?.regTrainerStop}
      </button>
      <LoadingLines className="trainer-counter-decoration" />
    </div>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, { hideTrainer, stopSession })(
  TrainerCounter
)
