import './registerRouter.css?v=1'
import { Fragment, useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import RegisterContext from 'context/register/registerContext'
import { connect, useSelector } from 'react-redux'
import { loadingStart, loadingEnd } from 'state/action-creators'

//Components
import ProgressBar from 'components/register/ProgressBar'
import FormPassword from 'components/forms/FormPassword'
import FormSex from 'components/forms/FormSex'
import FormAge from 'components/forms/FormAge'
import FormWeight from 'components/forms/FormWeight'
import FormHeight from 'components/forms/FormHeight'
import FormMeasures from 'components/forms/FormMeasures'
import FormMedical from 'components/forms/FormMedical'
import FormEquips from 'components/forms/FormEquips'
import FormObjective from 'components/forms/FormObjective'
import Modal from 'components/layout/Modal'

//Assets
import { ReactComponent as GoBackIcon } from 'assets/go-back.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/checkcircle.svg'

import Button from 'components/layout/Button'
import axios from 'axios'
import Spinner from 'components/Spinner'

interface RegisterRouterProps {
  state: any
  loadingStart: () => void
  loadingEnd: () => void
}

const RegisterRouter = (props: RegisterRouterProps) => {
  const state = props.state
  const langContent = state.translations
  const registerContext = useContext(RegisterContext)
  const history = useHistory()

  const { registerNewUser, composeUserInfo, composeNewUser, composeUserEquips } = registerContext

  const lang = (state.user.language_id as '1') || '1'
  const ct = langContent[lang]

  const { loadingStart, loadingEnd } = props

  const [regState, setRegState] = useState(0)
  const [finished, setFinished] = useState(false)

  const getUserInfo = (data: {}) => composeUserInfo(data)
  const getUserData = (data: {}) => composeNewUser(data)
  const getUserEquips = (data: {}) => composeUserEquips(data)

  if (!registerContext?.state?.newUser?.name && regState === 0) history.push('/')

  useEffect(() => {
    if (finished) {
      register()
    }
  }, [finished]) //eslint-disable-line

  const nextStep = () => {
    setRegState(regState + 1)
  }

  const goBack = () => {
    if (regState === 0) return history.push('/')
    setRegState(regState - 1)
  }

  const finish = (data: {}) => {
    composeUserInfo(data)
    setFinished(true)
  }

  const register = async () => {
    loadingStart()
    await registerNewUser()
    nextStep()
    loadingEnd()
  }

  const formStep = (state: number): any => {
    switch (state) {
      case 0:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="main-title">{ct?.regChoosePass}</h1>
              <h1 className="sec-title mt-1">{ct?.regChoosePassAux}</h1>
              <FormPassword next={nextStep} getData={getUserData} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 1:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regChooseGender}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regChooseGenderAux}</h1>
              <FormSex next={nextStep} getData={getUserInfo} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 2:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regChooseAge}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regChooseAgeAux}</h1>
              <FormAge next={nextStep} getData={getUserInfo} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 3:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regChooseWeight}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regChooseWeightAux}</h1>
              <FormWeight next={nextStep} getData={getUserInfo} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 4:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regChooseHeight}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regChooseHeightAux}</h1>
              <FormHeight next={nextStep} getData={getUserInfo} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 5:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regChooseMeasures}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regChooseMeasuresAux}</h1>
              <FormMeasures next={nextStep} getData={getUserInfo} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 6:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regChooseMedicalData}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regChooseMedicalDataAux}</h1>
              <FormMedical next={nextStep} getData={getUserInfo} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 7:
        return (
          <div className="pt-20 ath-container">
            <div className="w-product">
              <h1 className="w-product main-title">{ct?.regAthlEquips}</h1>
              <h1 className="w-product sec-title mt-1">{ct?.regAthlEquipsAux}</h1>
              <FormEquips next={nextStep} getData={(data) => getUserEquips(data)} buttonText={ct?.regSend} />
            </div>
          </div>
        )
      case 8:
        return <FormStep8 getData={finish} />
      case 9:
        return (
          <div className="pt-20 ath-container">
            <div className="screen-full-height">
              <div className="flex flex-col h-full justify-start text-center">
                <div className="grid-inputs">
                  <CheckCircleIcon className="w-full" />
                  <div className="text-3xl font-bold text-center">{ct?.regFinalTitle}</div>
                  <div className="sec-title">{ct?.regFinalText1}</div>
                  <div className="font-semibold text-center text-productPrimary">{ct?.regFinalText2}</div>
                </div>
              </div>
              <Button
                onClick={() => history.push('/home/panel')}
                className="main-button mb-10 flex-shrink-0"
                text={ct?.regFinalCTA}
              />
            </div>
          </div>
        )
    }
  }

  return (
    <Fragment>
      <div className="absolute bar-wrapper bg-white">
        <div className="bar bg-white">
          <button className="back-button" onClick={goBack}>
            {regState < 9 && <GoBackIcon fill="#666666" />}
          </button>
          <ProgressBar value={regState} max={9} />
        </div>
      </div>
      {formStep(regState)}
    </Fragment>
  )
}

const FormStep8 = (props: any) => {
  const { getData, onClick } = props
  const state = useSelector((state: any) => state.general)
  const lang = (state.user.language_id as '1') || 1
  const langContent = state.translations
  const ct = langContent[lang]
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(0)
  const [terms, setTerms] = useState<any>()
  const [policies, setPolicies] = useState<any>()

  async function contentLoad() {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/terms', {
      params: { language_id: state.user?.language_id },
    })
    const res1 = await axios.get(process.env.REACT_APP_BASE_URL + '/privacy-policy', {
      params: { language_id: state.user?.language_id },
    })

    setTerms(res.data)
    setPolicies(res1.data)
  }

  useEffect(() => {
    if (loading) contentLoad().then(() => setLoading(false))
  }, [loading]) //eslint-disable-line

  return (
    <Fragment>
      <div className="pt-20 ath-container">
        <div className="screen-full-height">
          <div>
            <h1 className="w-product main-title">{ct?.regChooseObjetive}</h1>
            <h1 className="w-product sec-title mt-1">{ct?.regChooseObjetiveAux}</h1>
          </div>
          <div onClick={onClick} className="h-full mb-10">
            <FormObjective next={() => {}} getData={(data) => getData(data)} buttonText={ct?.regEnd}>
              {!loading ? (
                <div>
                  <p className="w-full text-center text-xs text-gray-400">{ct?.regAgreement}</p>
                  <span className="text-xs">
                    <div className="flex w-full justify-center items-center">
                      <button
                        onClick={() => {
                          setModal(1)
                        }}
                        type="button"
                        className="policy-link"
                      >
                        {ct?.PrivacyPolicy}
                      </button>
                      <button disabled className="policy-spacer">
                        {ct?.and}
                      </button>
                      <button
                        onClick={() => {
                          setModal(2)
                        }}
                        type="button"
                        className="policy-link"
                      >
                        {ct?.Terms}
                      </button>
                    </div>
                  </span>
                </div>
              ) : (
                <div className="w-full flex justify-center items-center">
                  <Spinner />
                </div>
              )}
            </FormObjective>
          </div>
        </div>
      </div>
      {!loading && modal === 1 && (
        <Modal
          content={policies[0].text}
          title={ct?.PrivacyPolicy}
          onClick={() => {
            setModal(0)
          }}
        />
      )}
      {!loading && modal === 2 && (
        <Modal
          content={terms[0].text}
          title={ct?.Terms}
          onClick={() => {
            setModal(0)
          }}
        />
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps, {
  loadingStart,
  loadingEnd,
})(RegisterRouter)
