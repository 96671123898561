import { motion } from 'framer-motion'
import { MouseEventHandler } from 'react'
import './overlay.css'

interface OverlayProps {
  children: JSX.Element
  onClick?: MouseEventHandler
  className?: string
}

const Overlay = (props: OverlayProps) => {
  return (
    <motion.div
      onClick={props.onClick}
      className={props.className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.children}
    </motion.div>
  )
}

Overlay.defaultProps = {
  className: 'overlay',
}

export default Overlay
