//Hooks
import RegisterContext from "context/register/registerContext";
import { connect } from "react-redux";
import { Fragment, useContext, useEffect, useState } from "react";

//Components
import FormEquips from "components/forms/FormEquips";
import ProfileMenuBar from "components/profile/ProfileMenuBar";
import axios from "axios";
import Spinner from "components/Spinner";
import Button from "../../../components/layout/Button";
import { Link } from "react-router-dom";
import Drawer from "react-bottom-drawer";
import { useHistory } from "react-router";
import { loadingEnd, loadingStart, createWorkout } from "state/action-creators";

interface ProfileEquipsProps {
  state: any;
  loadingStart: () => void;
  loadingEnd: () => void;
  createWorkout: () => void;
}

const ProfileEquips = (props: ProfileEquipsProps) => {
  const history = useHistory();
  const { state, loadingStart, loadingEnd, createWorkout } = props;
  const langContent = state.translations;
  const ct = langContent[state.user.language_id as "1"];
  const [loading, setLoading] = useState(true);

  const registerContext = useContext(RegisterContext);
  const { updateEquips } = registerContext;

  const [equips, setEquips] = useState({ equipments: [] });
  const [equipsLoad, setEquipsLoad] = useState<any>();
  const [allEquipsLoad, setAllEquipsLoad] = useState<any>();
  const [initialState, setInitialState] = useState<{
    accessories: number[];
    elyptics: number;
    bycicles: number;
    stations: number;
    treadmills: number;
    platforms: number;
  }>({
    accessories: [],
    elyptics: -1,
    bycicles: -1,
    stations: -1,
    treadmills: -1,
    platforms: -1,
  });

  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => setShowPopup(!showPopup);

  async function contentLoad() {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/user-equipment",
      {}
    );
    let data = res.data;
    data = data.map(function (arr: any) {
      return arr["id"];
    });
    setEquipsLoad(data);

    const res1 = await axios.get(
      process.env.REACT_APP_BASE_URL + "/equipment/by-category",
      {
        params: { language_id: state.user?.language_id },
      }
    );
    setAllEquipsLoad(res1.data);
  }

  const onSubmit = async () => {
    loadingStart();
    await updateEquips(equips, true, true);
    await createWorkout();
    loadingEnd();
    history.push("/home/profile");
  };

  const getData = (data: { equipments: number[] }) => {
    togglePopup();
    // @ts-ignore
    setEquips({ ...equips, ...data });
  };

  const setInitialEquips = () => {
    if (!allEquipsLoad) return;

    let stateUpdate: any = { ...initialState };

    for (let key in allEquipsLoad) {
      if (key === "accessories") {
        allEquipsLoad[key].forEach(
          (option: { id: string; name: string; label: string }) => {
            if (equipsLoad.includes(option.id))
              stateUpdate[key] = [...stateUpdate[key], parseInt(option.id)];
          }
        );
      } else {
        allEquipsLoad[key].forEach(
          (option: { id: number; name: string; label: string }) => {
            if (equipsLoad.includes(option.id))
              stateUpdate = { ...stateUpdate, [key]: option.id };
          }
        );
      }
    }

    setInitialState(stateUpdate);
  };

  useEffect(() => {
    if (loading)
      contentLoad()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
  }, [loading]); //eslint-disable-line

  useEffect(() => {
    if (allEquipsLoad && equipsLoad) setInitialEquips();
  }, [allEquipsLoad]); //eslint-disable-line

  if (loading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  return (
    <div className="ath-container">
      <ProfileMenuBar text={ct?.profileMenuAthlEquips} to="/home/profile" />
      <FormEquips
        getData={getData}
        initialState={initialState}
        buttonText={ct?.profileSave}
      />
      <Drawer isVisible={showPopup} onClose={() => togglePopup()}>
        <Fragment>
          <div className="ath-container">
            <div className="w-product">
              <h1 className="my-5 main-title w-full">
                {ct?.profileMenuEquipWarn}
              </h1>
              <h1 className="text-sm text-gray-500">
                {ct?.profileMenuEquipWarnAux}
              </h1>
              <h1 className="text-black font-semibold text-sm mt-5 mb-16">
                {ct?.profileMenuObjectiveWarnAux2}
              </h1>
              <div className="grid-inputs">
                <Button
                  text={ct?.profileMenuObjectiveWarnYes}
                  onClick={onSubmit}
                />
                <Link to="/home/profile">
                  <button
                    name="objNo"
                    className=" text-productPrimary font-semibold w-full"
                  >
                    {ct?.profileMenuObjectiveWarnNo}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Fragment>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
});

export default connect(mapStateToProps, {
  loadingEnd,
  loadingStart,
  createWorkout,
})(ProfileEquips);
