import './related-articles.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

interface ArticleType {
  id: number
  title: string
  subtitle: string
  content: string
  reading_time: number
  goal: {
    id: number
    name: string
  }
  category: {
    id: number
    title: string
  }
  image: string
}

const RelatedArticles = (props: { state: any; categoryId: number; articleId: number }) => {
  const state = props.state
  const langContent = state.translations

  const ct = langContent[state.user.language_id as '1']
  const { content } = state

  const relatedContent = content.filter(
    (article: ArticleType) => article.category.id === props.categoryId && article.id !== props.articleId
  )

  if (relatedContent.length === 0) return null

  return (
    <div className="ath-container py-14">
      <h1 className="w-product font-bold text-lg pb-2">{ct?.contRelatedContent}</h1>
      <div className="related-selector w-screen">
        {relatedContent.map((e: ArticleType, index: number, array: []) => (
          <RelatedElement
            className={index === 0 ? 'related-first-item' : index === array.length - 1 ? 'related-last-item' : ''}
            key={e.id}
            to={'/home/content/article/' + e.id}
            image={e.image}
            title={e.title}
          />
        ))}
      </div>
    </div>
  )
}

interface RelatedElementProps {
  image?: string
  title: string
  to: string
  className?: string
}

const RelatedElement = (props: RelatedElementProps) => {
  return (
    <Link className={`${props.className} inline-block`} to={props.to}>
      <div className="flex flex-col">
        <div className="related-element" style={{ background: `url(${props.image}) center center/cover` }}></div>
        <div>
          <div className="related-title">{props.title}</div>
        </div>
      </div>
    </Link>
  )
}

RelatedElement.defaultProps = {
  className: '',
}

const mapStateToProps = (state: { general: any }) => ({
  state: state.general,
})

export default connect(mapStateToProps)(RelatedArticles)
