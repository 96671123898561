import { FormEventHandler } from 'react'
import './selectorButton.css'

interface SelectorButtonProps {
  name: string
  onChange: FormEventHandler
  options: [number, string][]
  value: number | string
}

export const SelectorButton = (props: SelectorButtonProps) => {
  const { name, onChange, options, value } = props
  return (
    <div className={`grid grid-cols-${options.length} gap-3 w-product`}>
      {options.map((e) => {
        return (
          <button
            type="button"
            name={name}
            key={e[0]}
            value={e[0]}
            onClick={onChange}
            className={`selector-button ${value === e[0] ? 'selected' : ''}`}
          >
            {e[1]}
          </button>
        )
      })}
    </div>
  )
}
